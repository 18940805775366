import {
  orderTemplatesQuery,
  fleetItemsQuery,
  filterItemsQuery,
  deleteItemsQuery,
  updateItemsQuery,
  addItemsQuery,
  fleetItemsLoadNextQuery,
} from './queries';
import {
  FLEET_ITEMS_REQUESTED,
  FLEET_ITEMS_DELETED,
  FLEET_ITEMS_REQUESTED_AFTER_UPDATE,
  FLEET_ITEMS_ADD,
  FLEET_ITEMS_SELECTED,
  FLEET_ITEMS_DELETE_REQUEST,
  FLEET_ITEMS_UPDATED,
  fleetItemsNextReceived,
  fleetItemsReceived,
  deleteFleetItems,
  requestFleetItemsAfterAdding,
  onFilterFleetItems,
  requestFleetItemsAfterUpdate,
  FLEET_ITEMS_REQUESTED_AFTER_ADDING,
} from './actions';
import { createApiCallEpic } from '../helpers';
import { combineEpics, ofType } from 'redux-observable';
import { mergeMap, map, pluck } from 'rxjs/operators';

const loadOrderTemplatesEpic = createApiCallEpic(
  orderTemplatesQuery,
  fleetItemsReceived,
);

const loadNextFleetItems = (action$, _state$, { api, logger }) => {

  return action$.pipe(
    ofType(FLEET_ITEMS_REQUESTED),
    mergeMap(action => api.graphApi(fleetItemsLoadNextQuery, {
      ...action.payload,
      input: {
        page: { index: action.payload.index, size: action.payload.size, brand: action.payload.brand, model: action.payload.model, machine: action.payload.machine, series: action.payload.series },
      },
    }).pipe(
      map(({ fleetItems }) =>
        fleetItemsNextReceived({ fleetItems }, !action.payload.brand && !action.payload.model && !action.payload.machine && !action.payload.series && action.payload.index !== 0  ? true : false)),
    )),
  );
};

const loadFleetItems = (action$, _state$, { api, logger }) => {
return action$.pipe(
  ofType(FLEET_ITEMS_REQUESTED_AFTER_UPDATE),
  mergeMap(action => api.graphApi(fleetItemsQuery, {
    ...action.payload,
    input: {
      page: { index: action.payload.index, size: action.payload.size, brand: action.payload.brand, model: action.payload.model, machine: action.payload.machine, series: action.payload.series },
    },
  }).pipe(
    map(({ fleetItems }) =>
      fleetItemsNextReceived({ fleetItems }, false, !action.payload.isExistingUpdated, action.payload.isExistingUpdated)),
  )),
);
};

const loadFleetItemsAfterAdding = (action$, _state$, { api, logger }) => {
  return action$.pipe(
    ofType(FLEET_ITEMS_REQUESTED_AFTER_ADDING),
    mergeMap(action => api.graphApi(fleetItemsQuery, {
      ...action.payload,
      input: {
        page: { index: action.payload.index, size: action.payload.size, brand: action.payload.brand, model: action.payload.model, machine: action.payload.machine, series: action.payload.series },
      },
    }).pipe(
      map(({ fleetItems }) =>
        fleetItemsNextReceived({ fleetItems }, false, !action.payload.isExistingUpdated, action.payload.isExistingUpdated)),
    )),
  );
};

const deleteFleetItem = (action$, _state$, { api, logger }) => {

  return action$.pipe(
    ofType(FLEET_ITEMS_DELETE_REQUEST),
    mergeMap(action => api.graphApi(deleteItemsQuery, {
      ...action.payload,
      input: {
      page: { brand: action.payload.brand, model: action.payload.model, machine: action.payload.machine, series: action.payload.series },
      },
    }).pipe(
      map(({ fleetItems }) =>
        deleteFleetItems(action.payload.id, action.payload.brand, action.payload.model, action.payload.machine, action.payload.series, action.payload.id)),
    )),
  );
};

const updateFleetItem = (action$, _state$, { api, logger }) => {

  return action$.pipe(
    ofType(FLEET_ITEMS_UPDATED),
    mergeMap(action => api.graphApi(updateItemsQuery, {
      ...action.payload,
      input: {
        page: { brand: action.payload.brand, model: action.payload.model, machine: action.payload.machine, series: action.payload.series, quantity: action.payload.quantity },
      },
    }).pipe(
      map(({ fleetItems }) =>
        requestFleetItemsAfterUpdate()),
    )),
  );
};

const addFleetItem = (action$, _state$, { api, logger }) => {
  return action$.pipe(
    ofType(FLEET_ITEMS_ADD),
    mergeMap(action => api.graphApi(addItemsQuery, {
      ...action.payload,
      input: {
        page: { brand: action.payload.brand, model: action.payload.model, machine: action.payload.machine, series: action.payload.series, quantity: action.payload.quantity },
      },
    }).pipe(
      map(({ addFleetItem }) =>
        requestFleetItemsAfterAdding(addFleetItem)),
      //mergeMap(({ fleetItems }) =>
      //  afterUpdateFleetItems(action.payload.id, action.payload.brand, action.payload.model, action.payload.machine, action.payload.quantity), requestFleetItemsAfterUpdate()),
    )),
  );
};

const filterFleetDropdown = (action$, _state$, { api, logger }) => {

  return action$.pipe(
    ofType(FLEET_ITEMS_SELECTED),
    mergeMap(action => api.graphApi(filterItemsQuery, {
      ...action.payload,
      input: {
        page: { brand: action.payload.brand, model: action.payload.model, machine: action.payload.machine, series: action.payload.series },
      },
    }).pipe(
      pluck('addFleetItems'),
      map(onFilterFleetItems),
    )),
  );
};

export default combineEpics(
  addFleetItem,
  deleteFleetItem,
  updateFleetItem,
  loadNextFleetItems,
  filterFleetDropdown,
  loadFleetItems,
  loadFleetItemsAfterAdding,
);


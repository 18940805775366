exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AccountVerification_actions-list,.AccountVerification_description{word-break:break-word}@supports (-ms-ime-align:auto){.AccountVerification_actions-list,.AccountVerification_description{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.AccountVerification_actions-list,.AccountVerification_description{word-wrap:break-word}}.AccountVerification_description{margin:0}.AccountVerification_actions-list{margin:0;padding:.75em 0;list-style-type:none}@supports (-ms-ime-align:auto){.AccountVerification_actions-list a,.AccountVerification_actions-list button{max-width:100%}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.AccountVerification_actions-list a,.AccountVerification_actions-list button{max-width:100%}}.AccountVerification_link-placeholder{margin-bottom:.7em;width:12em}.AccountVerification_contact-us-link,.AccountVerification_home-link,.AccountVerification_my-account-link,.AccountVerification_order-link{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"actions-list": "AccountVerification_actions-list",
	"actionsList": "AccountVerification_actions-list",
	"description": "AccountVerification_description",
	"link-placeholder": "AccountVerification_link-placeholder",
	"linkPlaceholder": "AccountVerification_link-placeholder",
	"contact-us-link": "AccountVerification_contact-us-link",
	"contactUsLink": "AccountVerification_contact-us-link",
	"home-link": "AccountVerification_home-link",
	"homeLink": "AccountVerification_home-link",
	"my-account-link": "AccountVerification_my-account-link",
	"myAccountLink": "AccountVerification_my-account-link",
	"order-link": "AccountVerification_order-link",
	"orderLink": "AccountVerification_order-link"
};
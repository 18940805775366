//[107329] [JMC] 3.4 Customized lookbook add-on � Show prefilled quantity input box

import { addProducts } from 'behavior/basket';
import { SanaButton } from 'components/primitives/buttons';
import { createQuantityModel, QuantityTextBox } from 'components/primitives/product';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getNumberDecimalsSeparator } from 'utils/format';
import { generateKey } from 'utils/helpers';

const AddProductButtonWithQty = ({
  id,
  productId,
  uom,
  className,
  placeholder,
  culture,
  qty,
  styles,
}) => {
  const [disabled, setDisabled] = useState(false);
  const qtyValue = (qty === undefined || qty === null || qty === '') ? '' : qty;
  const [qtyInput, setQty] = useState({ value: qtyValue, isValid: true });
  const dispatch = useDispatch();
  const { basketModifiedDate, basketUpdatedById } = useSelector(({ basket }) => ({
    basketModifiedDate: basket.modifiedDate,
    basketUpdatedById: basket.updated.updaterId,
  }), shallowEqual);
  const [componentId] = useState(generateKey);

  const addToBasket = useCallback(() => {
    if (disabled)
      return;

    if (!qtyInput.isValid)
      return;

    const quantity = qtyInput.value;
    const uomId = uom && uom.id;

    setDisabled(true);
    dispatch(addProducts([{ productId, uomId, quantity }], componentId));
  }, [productId, uom, qtyInput, quantityModel]);

  useEffect(() => {
    if (basketUpdatedById === componentId)
      setDisabled(false);
  }, [basketModifiedDate, basketUpdatedById]);

  const quantityModel = useMemo(() => {
    return createQuantityModel(uom, false);
  }, [uom]);

  const handleQuantityChange = useCallback(quantity => {
    setQty(quantity);
  }, []);

  const separator = getNumberDecimalsSeparator(culture);

  return (
    <div className={styles.addToBasket}>
      <div className={styles.qtyBox}>
        <QuantityTextBox
          id={id}
          quantity={quantityModel}
          value={qtyInput.value}
          onChange={handleQuantityChange}
          separator={separator}
          disabled={disabled}
        />
      </div>
      <div>
        <SanaButton
          className={className}
          textKey="AddToBasket"
          onClick={addToBasket}
          placeholder={placeholder}
          aria-disabled={disabled}
        />
      </div>
    </div>
  );
};

AddProductButtonWithQty.propTypes = {
  productId: PropTypes.string.isRequired,
  uom: PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantityStep: PropTypes.number,
    minimumQuantity: PropTypes.number,
    defaultQuantity: PropTypes.number,
  }),
  className: PropTypes.string,
  placeholder: PropTypes.node,
};

export default connect(({ localization }) => ({
  culture: localization.currentLanguage && localization.currentLanguage.cultureName,
}))(AddProductButtonWithQty);
//[107333][JMC] 3.9 Customer Registration � Avoid duplicate accounts OPTION 2
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { RouteName } from 'routes';
import { decapitalize } from 'utils/helpers';
import { loadSystemPageQuery } from '../system/queries';
import { pluckAsPage, getBackTo, initComponent } from '../helpers';
import { initSystemPageContent } from '../system';
import { of } from 'rxjs';
import { tap, switchMap, pluck, first } from 'rxjs/operators';
import { StoreType } from 'behavior/settings';

export default (routeData, _state$, { api }) => api.graphApi(loadSystemPageQuery('existingAccountEmail')).pipe(
  map(({ pages: { existingAccountEmail } }) => ({
    page: {
      ...existingAccountEmail,
      component: PageComponentNames.ExistingAccountEmail,
      index: false,
      backTo: routeData.options ? routeData.options.backTo : undefined,
    },
    routeData,
  })),  
  initSystemPageContent(),
);

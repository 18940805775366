import { memo } from 'react';
import { useSanaTexts, RichText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { makeSimpleText, makeRichText } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';
import { Link } from 'components/primitives/links';

export default memo(() => {
  const [
    existingGuestAccountTitle,    
  ] = useSanaTexts([
    'Registration_ExistingGuestAccountTitle',   
  ]).texts;
  const loginLink = (
    <Link to={routesBuilder.forLogin}>
      <RichText textKey="ExistingGuestAccount_Login" />
    </Link>
  );

  const registrationLink = (
    <Link to={routesBuilder.forRegistration}>
      <RichText textKey="ExistingGuestAccount_Register" />
    </Link>
  );
  
  return (
    <>
      <h2>{existingGuestAccountTitle}</h2>      
      <p >
        <RichText
          textKey="Registration_ExistingGuestAccountDescription"
          formatWith={[loginLink, registrationLink]}
        />
      </p>
    </>
  );
});
import styles from 'components/objects/product/Details.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { SimpleText, RichText } from 'components/sanaText';
import {
  ProductPrice,
  PricePer,
  ProductStock,
  OfflinePriceWarning,
  useCanViewUom,
  UomSelector,
} from 'components/primitives/product';
import { useProductContext } from './hooks';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import QuantityBox from './QuantityBox';
import OrderBoxForm from './OrderBoxForm';
import { SanaButton } from 'components/primitives/buttons';
import { VariantDropdowns } from './variantDropdowns';
import { VolumePricesButton } from './volumePrices';
import { showMatrix } from './showMatrix';
import { Placeholder } from 'components/primitives/placeholders';
import { usePrintMode } from 'utils/hooks';
import ConfigureButton from '../productConfigurator/ConfigureButton';
import { BomComponents } from './bomComponents';
import { VariantsMatrixPopup } from './variantsMatrix';
import { AddToWishlist } from './wishlist';
import { AgreementSelector } from './salesAgreements';

const OrderBox = ({
  matrixPreset,
  abilities: [orderProductsAbility, useSalesAgreementsAbility],
  wishListEnabled,
  allowUOMSelection,
  salesAgreement,
}) => {
  const { product, calculatedInfo, pricesInclTax, updateUomId, uomId: selectedUomId } = useProductContext();
  const isPrintMode = usePrintMode();
  const canViewUom = useCanViewUom();

  if (!product)
    return null;

  const { price, listPrice, inventory, isOrderable } = calculatedInfo;

  const {
    stockLevels,
    hasVariants,
    isOrderable: productOrderable,
    productConfiguratorInfo,
    hasVolumePrices,
  } = product;

  const uomId = canViewUom && product.uom && product.uom.id;
  const canOrderProducts = orderProductsAbility === AbilityState.Available;

  const pricePlaceholder = <Placeholder className={styles.pricePlaceholder} />;
  const taxSuffixPlaceholder = <Placeholder className={styles.taxSuffixPlaceholder} />;
  const availabilityTextPlaceholder = <Placeholder className={styles.availabilityTextPlaceholder} />;
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const priceRowPlaceholder = <Placeholder className={styles.priceRowPlaceholder} />;
  const availabilityPlaceholder = <Placeholder className={styles.availabilityPlaceholder} />;

  if (matrixPreset && product.hasVariants) {
    const matrixSupported = product.variantComponentGroups?.length <= 2;
    if (isPrintMode && matrixSupported)
      return null;

    const calculationInfoIsLoaded = product.variants.some(v => v.isOrderable !== undefined);

    if (matrixSupported && !calculationInfoIsLoaded && productOrderable)
      return btnPlaceholder;

    if (productOrderable && showMatrix(product)) {
      const showBomComponents = !!product.bomComponents?.length;
      return (
        <>
          <VariantsMatrixPopup />
          {showBomComponents && <BomComponents />}
          {hasVolumePrices && <VolumePricesButton showCompleteList canOrderProducts={canOrderProducts} />}
        </>
      );
    }
  }

  const shouldShowOrderBox = price !== null
    || (inventory !== null && stockLevels != null)
    || (productOrderable && hasVariants)
    || (canOrderProducts && isOrderable && productOrderable)
    || (canOrderProducts && isOrderable === false);

  if (!shouldShowOrderBox)
    return null;

  const isProductOrderable = isOrderable && productOrderable;
  const showAddToWishList = wishListEnabled && !(productConfiguratorInfo && productConfiguratorInfo.isProductConfigurable);

  let orderBox = null;
  if (!isPrintMode) {
    if (canOrderProducts) {
      const shouldShowAgreementSelector = productOrderable
        && useSalesAgreementsAbility === AbilityState.Available
        && salesAgreement;
      orderBox = (
        <OrderBoxForm>
          {shouldShowAgreementSelector &&
            <AgreementSelector
              allowUomSelection={allowUOMSelection}
              canViewUom={canViewUom}
              salesAgreement={salesAgreement}
            />
          }
          <QuantityBox disabled={!isProductOrderable} />
          {isProductOrderable &&
            <Row className={styles.row} crossAxisAlign="center">
              <Col xs={12} sm={{ size: 'auto', offset: 3 }} md={{ offset: 0 }} className={styles.limitedWidth}>
                {productConfiguratorInfo.isProductConfigurable
                  ? <ConfigureButton />
                  : (
                    <SanaButton
                      textKey="AddToBasket"
                      type="submit"
                      className={`${btnStyles.btnAction} ${btnStyles.btnBig} ${styles.addToBasketBtn}`}
                      placeholder={btnPlaceholder}
                    />
                  )
                }
              </Col>
              <Col xs={12} className={styles.gutter} />
              <Col xs={12} sm="auto" className={styles.limitedWidth}>
                {showAddToWishList &&
                  <div className={styles.afterBtnLinks}>
                    <AddToWishlist />
                  </div>
                }
              </Col>
            </Row>
          }
        </OrderBoxForm>
      );
    }
    else if (uomId && allowUOMSelection && product.uoms?.length > 1) {
      orderBox = (
        <Row>
          <Col xs={{ size: 9, offset: 3 }} lg={{ size: 10, offset: 2 }}>
            <UomSelector
              className={styles.uom}
              productId={product.id}
              allowUOMSelection={allowUOMSelection}
              uomId={selectedUomId || uomId}
              uoms={product.uoms}
              onUomChange={updateUomId}
              isOrderable={isOrderable}
            />
          </Col>
        </Row>
      );
    }
  }

  return (
    <>
      {!isPrintMode && <BomComponents />}
      <div className={styles.orderBox}>
        {price !== null && (
          typeof price !== 'undefined'
            ? (
              <Row className={styles.row} crossAxisAlign="center">
                <Col xs={3} lg={2} className={styles.ellipsis} id="price_Label">
                  <SimpleText textKey="Price" placeholder={pricePlaceholder} />
                </Col>
                <Col xs={9} lg={10} className={styles.field}>
                  <Row className={styles.prices}>
                    <ProductPrice salesPrice={price} basePrice={listPrice} />
                    <Col xs={12} sm="auto" className={styles.afterPrice}>
                      {uomId && <PricePer uomId={uomId} uoms={product.uoms} />}
                      <div>
                        {pricesInclTax != null && (
                          <SimpleText
                            textKey={pricesInclTax ? 'InclTaxSuffix' : 'ExclTaxSuffix'}
                            placeholder={taxSuffixPlaceholder}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  {!isPrintMode && hasVolumePrices && <VolumePricesButton canOrderProducts={canOrderProducts} />}
                </Col>
                {!isPrintMode && (
                  <Col>
                    <OfflinePriceWarning />
                  </Col>
                )}
              </Row>
            )
            : priceRowPlaceholder
        )}
        {inventory !== null && stockLevels != null && (
          <>
            {
              typeof inventory === 'number'
                ? (
                  <Row className={styles.row} crossAxisAlign="center">
                    <Col xs={3} lg={2} className={styles.ellipsis} id="availability_Label">
                      <SimpleText textKey="Availability" placeholder={availabilityTextPlaceholder} />
                    </Col>
                    <Col xs={9} lg={10} aria-labelledby="availability_Label">
                      <ProductStock inventory={inventory} stockLevels={stockLevels} />
                    </Col>
                  </Row>
                )
                : availabilityPlaceholder
            }
          </>
        )}
        {productOrderable && hasVariants && <VariantDropdowns />}
        {orderBox}
        {canOrderProducts && isOrderable === false && (
          <div className={styles.cannotOrder}>
            <RichText textKey={hasVariants ? 'ProductVariantCannotBeOrderedAtThisTime' : 'ProductCannotBeOrderedAtThisTime'} />
            {showAddToWishList && !isPrintMode && <>{' '}<AddToWishlist /></>}
          </div>
        )}
      </div>
    </>
  );
};

OrderBox.propTypes = {
  abilities: abilitiesPropType,
  matrixPreset: PropTypes.bool,
  wishListEnabled: PropTypes.bool,
  allowUOMSelection: PropTypes.bool,
  salesAgreement: PropTypes.object,
};

const mapStateToProps = ({
  settings: {
    wishListEnabled,
    product,
  },
  page: { salesAgreement },
}) => ({
  wishListEnabled,
  allowUOMSelection: product && product.allowUOMSelection,
  salesAgreement,
});

export default withAbilities(
  connect(mapStateToProps)(OrderBox),
  [AbilityTo.OrderProducts, AbilityTo.UseSalesAgreements],
);

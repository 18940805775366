import styles from './OrderTemplates.module.scss';
import { memo, useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { UseSanaTexts, SimpleText} from 'components/sanaText';
import { useAbilities } from 'components/objects/user';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { useIsPreview } from 'components/objects/preview';
import { deleteFleetItemsRequest, updateFleetItems } from 'behavior/pages/fleetItems';
import { Input } from 'components/primitives/form';
import { Link } from 'components/primitives/links';
import { TrashCanIcon, SaveIcon, EditButtonIcon } from 'components/primitives/icons';

const Item = ({ fleetItem, isUpdated }) => {
  const dispatch = useDispatch();
  const [viewUomAbility] = useAbilities([AbilityTo.ViewUnitOfMeasure]).abilities;
  const [disabled, setDisabled] = useState(true);
  const [showQtyBox, setQtyBox] = useState(false);
  const [showEditButton, setEditButton] = useState(false);
  const [quantity, setQuantity] = useState(fleetItem.quantity);
  const inputRef = useRef();
  const isPreview = useIsPreview();

  if (isPreview)
    return null;

  function handleSubmit(e) {

    e.preventDefault();
    const brandId = e.target.elements && e.target.elements['brandId'];
    const brand = brandId && brandId.value;

    const modelId = e.target.elements && e.target.elements['modelId'];
    const model = modelId && modelId.value;

    const machineId = e.target.elements && e.target.elements['machineId'];
    const machine = machineId && machineId.value;

    const elementId = e.target.elements && e.target.elements['id'];
    const id = elementId && elementId.value;

    dispatch(deleteFleetItemsRequest(brand, model, machine, id));
  }

  function updateQuantity(id, brand, model, machine, quantity) {
    dispatch(updateFleetItems(id, brand, model, machine, quantity));
    setQtyBox(!showQtyBox);
    setEditButton(!showEditButton);
  }

  function handleQuantityBox() {
    setQtyBox(!showQtyBox);
    setEditButton(!showEditButton);
  }

  function handleQuantity(e) {
    setQuantity(e.target.value);
  }

  return (
    <tr aria-labelledby={''}>
      <td style={{ display: 'none' }}>
        {fleetItem.id}
      </td>
      <td id={''}>
        {fleetItem.brandDescription}
      </td>
      <td>
        <div className={''}>
          {fleetItem.machineDescription}
        </div>
      </td>
      <td className={styles.modelColumn}>
        <div className={''}>
          {fleetItem.modelDescription}
        </div>
      </td>
      <td className={styles.qtyColumn}>
        <div className={''}>
          <span type="text" style={{ display: !showQtyBox ? 'block' : 'none' }}>{quantity}</span>
          <div className="form-row-control">
            <div className="test">
              <input className={ styles.inputShort } type="text" ref={inputRef} style={{ display: showQtyBox ? 'inline-block' : 'none' }} onChange={e => handleQuantity(e)} /*disabled={showQtyBox ? '' : 'disabled'}*/ />
            </div>
          </div>
        </div>
      </td>
      <td>
        <Link
          className="Link_arrowed"
          url={fleetItem.url}
        >
          <SimpleText textKey="ViewLink" />
          </Link>
      </td>
      <td className={styles.column48}>
        <span className={styles.inlineSpan}>
        <button className={styles.btnIcon} type="button" style={{ display: !showEditButton ? 'block' : 'none' }} onClick={() => handleQuantityBox()}>
            <EditButtonIcon className={styles.icon} />
          </button>
        </span>
        <span className={styles.inlineSpan}>
        <button className={styles.btnIcon} style={{ display: showEditButton ? 'block' : 'none' }} onClick={() => updateQuantity(fleetItem.id, fleetItem.brand, fleetItem.model, fleetItem.machine, quantity)} >
            <SaveIcon className={styles.icon} />
          </button>
        </span>
      </td>
      <td className={styles.column48}>
        <form onSubmit={handleSubmit}>
          <span style={{ display: 'none' }}>
            <Input maxLength="50" type="search" value={fleetItem.id} name="id" id="id" />
            <Input maxLength="50" type="search" value={fleetItem.brand} name="brandId" id="brandId" />
            <Input maxLength="50" type="search" value={fleetItem.model} name="modelId" id="modelId" />
            <Input maxLength="50" type="search" value={fleetItem.machine} name="machineId" id="machineId" />
          </span>
          <button className={styles.btnIcon} type="submit">
            <TrashCanIcon className={styles.icon} />
          </button>
        </form>
      </td>
    </tr>
  );
};

Item.propTypes = {
  fleetItem: PropTypes.object.isRequired,
};

export default memo(Item);


import styles from './OrderTemplates.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { RichText, SimpleText, UseSanaTexts } from 'components/sanaText';
//import Actions from './Actions';
import { connect } from 'react-redux';
import Item from './Item';
import AddFleetItem from './AddFleetItem';
import { requestFleetItems, fleetItemsSetDefault } from 'behavior/pages/fleetItems';
import { LoadMoreButton, SanaButton } from 'components/primitives/buttons';
import { Input } from 'components/primitives/form';
import { toasts } from 'behavior/toasts';
import { useIsOffline } from 'utils/hooks';
import { InfoAlert } from 'components/primitives/alerts';
import { deleteFleetItemsRequest, updateFleetItems } from 'behavior/pages/fleetItems';
import { Link } from 'components/primitives/links';
import { TrashCanIcon, SaveIcon, EditButtonIcon } from 'components/primitives/icons';
import { Table } from 'components/primitives/table';

const PAGE_SIZE = 5;
const searchFormName = 'FleetItems';

const List = ({ items, brand, model, machine, dispatch, isUpdated, isExistingUpdated,
  brandCategoryOption, modelCategoryOption, seriesCategoryOption, machineCategoryOption,
  isSearchFailed, isDeleted, isSearchSuccess }) => {

  const [isLoading, setLoading] = useState(false);
  const searchParam = { brand, model, machine };
  const searchedIdRef = useRef(searchParam);
  const inputRef = useRef(null);
  const [showQtyBox, setQtyBox] = useState(false);
  const [id, SetId] = useState('');
  const [showEditButton, setEditButton] = useState(false);
  const [quantity, setQuantity] = useState('');

  if (isSearchFailed) {
    toasts.error(<RichText textKey="FleetItems_NoSearchResults" />);
  }

  if (isDeleted) {
    toasts.success(<RichText textKey="FleetItems_Deleted" />);
    dispatch(fleetItemsSetDefault());
    dispatch(requestFleetItems(0, PAGE_SIZE));
  }

  if (isUpdated) {
    toasts.success(<RichText textKey="FleetItems_Updated" />);
  }

  if (isExistingUpdated) {
    toasts.success(<RichText textKey="UpdatedExistingItem" />);
    dispatch(fleetItemsSetDefault());
  }

  const loadNextBatch = useCallback(index => {
    dispatch(requestFleetItems(index, PAGE_SIZE));
  }, []);

  const isOffline = useIsOffline();
  if (isOffline) {
    return (
      <InfoAlert>
        <RichText textKey="FleetItemsIsNotAvailable" />
      </InfoAlert>
    );
  }

  if (!items)
    return null;

  if (!items.length) {
    return (
      <>
        <AddFleetItem
          brandCategoryOption={brandCategoryOption}
          modelCategoryOption={modelCategoryOption}
          machineCategoryOption={machineCategoryOption}
          seriesCategoryOption={seriesCategoryOption}
        />
        <form onSubmit={handleSubmit} name={searchFormName} style={{display: 'none'}}>
          <div className="Grid_row SanaForm_form-row">
            <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 SanaForm_control form-row-control SanaForm_form-row">
              <div className="SanaForm_label form-row-label">
                <label className="SanaForm_label form-row-label" htmlFor="brandId"><SimpleText textKey="BrandId" /></label>
              </div>
              <div className="test">
                <Input inputRef={inputRef} type="search" name="brandId" id="brandId" />
              </div>
            </div>
            <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 SanaForm_control form-row-control SanaForm_form-row">
              <div className="SanaForm_label form-row-label">
                <label className="SanaForm_label form-row-label" htmlFor="modelId"><SimpleText textKey="ModelId" /></label>
              </div>
              <div className="test">
                <Input inputRef={inputRef} type="search" name="modelId" id="modelId" />
              </div>
            </div>
            <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 SanaForm_control form-row-control SanaForm_form-row">
              <div className="SanaForm_label form-row-label">
                <label className="SanaForm_label form-row-label" htmlFor="machineId"><SimpleText textKey="MachineID" /></label>
              </div>
              <div className="test">
                <Input inputRef={inputRef} type="search" name="machineId" id="machineId" />
              </div>
            </div>
          </div>
          <div className="Grid_row">
            <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 Grid_col-sm-12 Grid_align-self-auto SanaForm_form-row">
              <div className="SanaForm_form-row Filter_submit-row text-right">
                <SanaButton textKey="Search" className={`${btnStyles.btnLoadMore} ${isLoading && !isSearchSuccess ? btnStyles.loading : ''} 'margin-top-15'`} />
              </div>
            </div>
            <div className="SanaForm_label form-row-label Grid_col-12">
              <h3 className><SimpleText textKey="Fleet_Item_Grid" /></h3>
            </div>
          </div>
        </form>
        <div className="SanaForm_label form-row-label Grid_col-12">
          <h3 className><SimpleText textKey="Fleet_Item_Grid" /></h3>
        </div>
        <div className="msg-block">
          <RichText textKey="MyAccount_FleetItems_NoFleetItems" />
        </div>
      </>
    );
  }
  function handleSubmit(e) {
    e.preventDefault();
    const brandId = e.target.elements && e.target.elements['brandId'];
    const brand = brandId && brandId.value;

    const modelId = e.target.elements && e.target.elements['modelId'];
    const model = modelId && modelId.value;

    const machineId = e.target.elements && e.target.elements['machineId'];
    const machine = machineId && machineId.value;

    if (!brand && !model && !machine) {
      dispatch(requestFleetItems(0, PAGE_SIZE, brand, model, machine));
    }
    setLoading(!isLoading);
    dispatch(fleetItemsSetDefault());
    //brand
    if (brand) {
      if (!brand.trim()) {
        brandId.value = '';
      }
    } else {
      if (brandId)
        brandId.value = '';
    }
    //model
    if (model) {
      if (!model.trim()) {
        modelId.value = '';
      }
    } else {
      if (modelId)
        modelId.value = '';
    }
    //Machine
    if (machine) {
      if (!machine.trim()) {
        machineId.value = '';
      }
    }
    else {
      if (machineId)
        machineId.value = '';
    }
    searchedIdRef.current = { brandId, modelId, machineId };
    dispatch(requestFleetItems(0, PAGE_SIZE, brand, model, machine));
    searchedIdRef.current = null;
    setLoading(!isLoading);
    return;
  }

  function handleDelete(e) {

    e.preventDefault();
    const brandId = e.target.elements && e.target.elements['brandId'];
    const brand = brandId && brandId.value;

    const modelId = e.target.elements && e.target.elements['modelId'];
    const model = modelId && modelId.value;

    const machineId = e.target.elements && e.target.elements['machineId'];
    const machine = machineId && machineId.value;

    const seriesId = e.target.elements && e.target.elements['seriesId'];
    const series = seriesId && seriesId.value;

    const elementId = e.target.elements && e.target.elements['id'];
    const id = elementId && elementId.value;

    dispatch(deleteFleetItemsRequest(brand, model, machine, series,id));
  }

  function updateQuantity(id, brand, model, machine,series, initialaty, quantity) {
    if (initialaty === quantity) {
      SetId(id);
      setQuantity(quantity);
      setQtyBox(!showQtyBox);
      setEditButton(!showEditButton);
      dispatch(fleetItemsSetDefault());
      return;
    }
    SetId(id);
    setQuantity(quantity);
    dispatch(updateFleetItems(id, brand, model, machine,series, quantity));
    setQtyBox(!showQtyBox);
    setEditButton(!showEditButton);
    dispatch(fleetItemsSetDefault());
  }

  function handleQuantityBox(id, quantity) {
    SetId(id);
    setQtyBox(!showQtyBox);
    setEditButton(!showEditButton);
    setQuantity(quantity);
    dispatch(fleetItemsSetDefault());
  }

  function handleQuantity(e) {
    setQuantity(e.target.value);
    dispatch(fleetItemsSetDefault());
  }

  return (
    <>
      <AddFleetItem
        brandCategoryOption={brandCategoryOption}
        modelCategoryOption={modelCategoryOption}
        machineCategoryOption={machineCategoryOption}
        seriesCategoryOption={seriesCategoryOption}
        isExistingUpdated={isExistingUpdated}
      />

      <form onSubmit={handleSubmit} name={searchFormName} style={{ display: 'none' }}>
        <div className="Grid_row SanaForm_form-row">
          <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 SanaForm_control form-row-control SanaForm_form-row">
            <div className="SanaForm_label form-row-label">
              <label className="SanaForm_label form-row-label" htmlFor="brandId"><SimpleText textKey="BrandId" /></label>
            </div>
            <div className="test">
              <Input inputRef={inputRef} type="search" name="brandId" id="brandId" />
            </div>
          </div>
          <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 SanaForm_control form-row-control SanaForm_form-row">
            <div className="SanaForm_label form-row-label">
              <label className="SanaForm_label form-row-label" htmlFor="modelId"><SimpleText textKey="ModelId" /></label>
            </div>
            <div className="test">
              <Input inputRef={inputRef} type="search" name="modelId" id="modelId" />
            </div>
          </div>
          <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 SanaForm_control form-row-control SanaForm_form-row">
            <div className="SanaForm_label form-row-label">
              <label className="SanaForm_label form-row-label" htmlFor="machineId"><SimpleText textKey="MachineID" /></label>
            </div>
            <div className="test">
              <Input inputRef={inputRef} type="search" name="machineId" id="machineId" />
            </div>
          </div>
        </div>
        <div className="Grid_row">
          <div className="Grid_col-lg-6 Grid_col-xl-6 Grid_col-md-6 Grid_col-sm-12 Grid_align-self-auto SanaForm_form-row">
            <div className="SanaForm_form-row Filter_submit-row text-right">
              <SanaButton textKey="Search" className={`${btnStyles.btnLoadMore} ${isLoading && !isSearchSuccess ? btnStyles.loading : ''} 'margin-top-15'`} />
            </div>
          </div>
          <div className="SanaForm_label form-row-label Grid_col-12">
            <h3 className><SimpleText textKey="Fleet_Item_Grid" /></h3>
          </div>
        </div>
      </form>

      <div className="SanaForm_label form-row-label ">
        <h3 className><SimpleText textKey="Fleet_Item_Grid" /></h3>
      </div>

      <div className="SanaForm_form-row table-left-all">
        <Table className={`${tableStyles.table} ${styles.templatesTable}`}>
          <thead>
            <tr>
              <th style={{ display: 'none' }}> &nbsp; </th>
              <th className="brand"><SimpleText textKey="FleetItem_Brand" /></th>
              <th className="machine"><SimpleText textKey="FleetItem_Machine" /></th>
              <th className="machine"><SimpleText textKey="FleetItem_Series" /></th>
              <th className={styles.modelColumn}><SimpleText textKey="FleetItem_Model" /></th>
              <th className={styles.qtyColumn}><SimpleText textKey="FleetItem_Quantity" /></th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {items.map(fleetItem => (
              <tr aria-labelledby={''}>
                <td style={{ display: 'none' }}>
                  {fleetItem.id}
                </td>
                <td id={''}>
                  {fleetItem.brandDescription}
                </td>
                <td>
                  <div className={''}>
                    {fleetItem.machineDescription}
                  </div>
                </td>
                <td>
                  <div className={''}>
                    {fleetItem.seriesDescription}
                  </div>
                </td>
                <td className={styles.modelColumn}>
                  <div className={''}>
                    {fleetItem.modelDescription}
                  </div>
                </td>
                <td className={styles.qtyColumn}>
                  <div className={''}>
                    <span type="text" style={{ display: !showQtyBox || fleetItem.id !== id ? 'block' : 'none' }}>{fleetItem.id !== id ? fleetItem.quantity : quantity}</span>
                    <div className="form-row-control">
                      <div className="test">
                        <input className={styles.inputShort} type="text" value={quantity} ref={inputRef} style={{ display: showQtyBox && fleetItem.id === id ? 'inline-block' : 'none' }} onChange={e => handleQuantity(e)} /*disabled={showQtyBox ? '' : 'disabled'}*/ />
                      </div>
                    </div>
                  </div>
                </td>
                <td className={styles.tableTextCenter}>
                  <Link
                    className="Link_arrowed"
                    url={fleetItem.url}
                  >
                    <SimpleText textKey="ViewLink" />
                  </Link>
                </td>
                <td className={styles.column48}>
                  <span className={styles.inlineSpan}>
                    <button className={styles.btnIcon} type="button" style={{ display: !showEditButton || fleetItem.id !== id ? 'block' : 'none' }} onClick={() => handleQuantityBox(fleetItem.id, fleetItem.quantity)}>
                      <EditButtonIcon className={styles.icon} />
                    </button>
                  </span>
                  <span className={styles.inlineSpan}>
                    <button className={styles.btnIcon} style={{ display: showEditButton && fleetItem.id === id ? 'block' : 'none' }} onClick={() => updateQuantity(fleetItem.id, fleetItem.brand, fleetItem.model, fleetItem.machine, fleetItem.series, fleetItem.quantity, quantity)}>
                      <SaveIcon className={styles.icon} />
                    </button>
                  </span>
                </td>
                <td className={styles.column48}>
                  <form onSubmit={handleDelete}>
                    <span style={{ display: 'none' }}>
                      <Input maxLength="50" type="search" value={fleetItem.id} name="id" id="id" />
                      <Input maxLength="50" type="search" value={fleetItem.brand} name="brandId" id="brandId" />
                      <Input maxLength="50" type="search" value={fleetItem.model} name="modelId" id="modelId" />
                      <Input maxLength="50" type="search" value={fleetItem.machine} name="machineId" id="machineId" />
                      <Input maxLength="50" type="search" value={fleetItem.series} name="seriesId" id="seriesId" />
                    </span>
                    <button className={styles.btnIcon} type="submit">
                      <TrashCanIcon className={styles.icon} />
                    </button>
                  </form>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="Grid_row SanaForm_form-row">
        <div className="Grid_col-12 Grid_align-self-auto">
          <LoadMoreButton textKey="ShowNextNFleetItems"
            loadedCount={items.length}
            size={PAGE_SIZE}
            totalCount={items[0].totalCount}
            loadNext={loadNextBatch}
          />
        </div>
      </div>
    </>
  );
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })),
  brandCategoryOption: PropTypes.array,
  modelCategoryOption: PropTypes.array,
  machineCategoryOption: PropTypes.array,
  seriesCategoryOption: PropTypes.array,
  status: PropTypes.object,
  isUpdated: PropTypes.bool,
  isExistingUpdated: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isSearchSuccess: PropTypes.bool,
  isSearchFailed: PropTypes.bool,
  dispatch: PropTypes.func,
};

export default connect(({
  fleetItems: { items, status, brand, model, machine, isUpdated, isExistingUpdated, brandCategoryOption, machineCategoryOption, modelCategoryOption, seriesCategoryOption, isSearchFailed, isDeleted, isSearchSuccess },
  localization,
}) => ({
  items,
  status,
  brand,
  model,
  machine,
  isUpdated,
  isExistingUpdated,
  brandCategoryOption,
  machineCategoryOption,
  modelCategoryOption,
  seriesCategoryOption,
  isSearchFailed,
  isDeleted,
  isSearchSuccess,
}))(List);
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExistingAccountPhone_actions-list,.ExistingAccountPhone_description{word-break:break-word}@supports (-ms-ime-align:auto){.ExistingAccountPhone_actions-list,.ExistingAccountPhone_description{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ExistingAccountPhone_actions-list,.ExistingAccountPhone_description{word-wrap:break-word}}.ExistingAccountPhone_description{margin:0}.ExistingAccountPhone_actions-list{margin:0;padding:.75em 0;list-style-type:none}@supports (-ms-ime-align:auto){.ExistingAccountPhone_actions-list a,.ExistingAccountPhone_actions-list button{max-width:100%}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ExistingAccountPhone_actions-list a,.ExistingAccountPhone_actions-list button{max-width:100%}}.ExistingAccountPhone_link-placeholder{margin-bottom:.7em;width:12em}.ExistingAccountPhone_contact-us-link,.ExistingAccountPhone_home-link,.ExistingAccountPhone_my-account-link,.ExistingAccountPhone_order-link{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"actions-list": "ExistingAccountPhone_actions-list",
	"actionsList": "ExistingAccountPhone_actions-list",
	"description": "ExistingAccountPhone_description",
	"link-placeholder": "ExistingAccountPhone_link-placeholder",
	"linkPlaceholder": "ExistingAccountPhone_link-placeholder",
	"contact-us-link": "ExistingAccountPhone_contact-us-link",
	"contactUsLink": "ExistingAccountPhone_contact-us-link",
	"home-link": "ExistingAccountPhone_home-link",
	"homeLink": "ExistingAccountPhone_home-link",
	"my-account-link": "ExistingAccountPhone_my-account-link",
	"myAccountLink": "ExistingAccountPhone_my-account-link",
	"order-link": "ExistingAccountPhone_order-link",
	"orderLink": "ExistingAccountPhone_order-link"
};
import styles from './ExistingAccountModal.module.scss';
import buttonStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/objects/modals';
import { SanaButton } from 'components/primitives/buttons';

const ExistingAccountModal = ({ opened, hide, children }) => {
  return (
    <Modal opened={opened} hide={hide} className="wish-list-confirmation-modal">
      <div className={styles.modalContainer}>
        {children}
        <div className={styles.modalButtonsBlock}>
          <SanaButton
            textKey="Ok"
            className={`${buttonStyles.btnExtraSmall} ${buttonStyles.noIcon} ${styles.btnConfirm}`}
            onClick={hide}
          />
        </div>
      </div>
    </Modal>
  );
};

ExistingAccountModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(ExistingAccountModal);
import { BASKET_PAGE_REQUESTED, BASKET_RECEIVED } from 'behavior/basket';
import { ORDER_TEMPLATE_SAVED } from '../orderTemplates/actions';

export default function (state, action) {
  switch (action.type) {
    case BASKET_PAGE_REQUESTED:
      return { ...state, linesUpdating: true };
    case BASKET_RECEIVED:
      return { ...state, linesUpdating: false };

    //107334_3.10_ShopAccount_Mechanic-ordertemplate-error
    case ORDER_TEMPLATE_SAVED:
      return { ...state, savingResult: action.payload.savingResult };

    default:
      return state;
  }
}
import styles from '../Checkout.module.scss';
import { useEffect, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { AddressStep as AddressStepSection, GuestAddressStepBody } from '../base/address';
import CheckoutContext from './CheckoutContext';
import { extractEscapedTemplateFields, validateForm } from 'components/objects/forms';
import { Steps } from 'behavior/pages/checkout';
import { saveGuest } from 'behavior/pages/checkout';
import scrollIntoView from 'scroll-into-view';
import { useModal } from 'components/objects/modals';
import ExistingAccountMessage from 'components/objects/registration/ExistingAccountMessage';
import ExistingAccountModal from 'components/objects/registration/ExistingAccountModal';
import ExistingGuestAccountMessage from 'components/objects/registration/ExistingGuestAccountMessage';
import ExistingGuestAccountModal from 'components/objects/registration/ExistingGuestAccountModal';
import { useOnChange } from 'utils/hooks';
const GuestAddressStep = ({ info, isCompleted }) => {
  const {
    profileFields,
    profileTemplateFields,
    shippingTemplateFields,
    shippingAddress,
    isShowExistingAccountPopup,
    isShowExistingGuestAccountPopup,
  } = info;

  const profileFormikRef = useRef();
  const shippingFormikRef = useRef();
  shippingFormikRef.current = null;
  const [showValidation, setShowValidation] = useState();
  const { setLoading, registerStep } = useContext(CheckoutContext);
  const { opened, toggle } = useModal();

  useEffect(() => {
    const validateStep = async () => {
      const formValid = await validateForm(profileFormikRef);
      const withShippingAddress = !!shippingFormikRef.current;
      const shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));
      if (!formValid || !shippingFormValid) {
        setShowValidation(true);
        scrollIntoView(document.querySelector(`.${styles.validation}`), { time: 200 });        
      }

      return formValid && shippingFormValid;
    };

    registerStep(Steps.Address, styles.address, [Steps.Address], validateStep);
  }, []);

  useOnChange(() => {
    toggle();
  }, [info], false);

  const dispatch = useDispatch();

  const onAddressFormSubmit = async () => {

    const formValid = await validateForm(profileFormikRef);
    const withShippingAddress = !!shippingFormikRef.current;

    const shippingFormValid = !withShippingAddress || (await validateForm(shippingFormikRef));

    if (!formValid || !shippingFormValid)
      return;

    setLoading(Steps.Address);
    const formData = extractEscapedTemplateFields(profileFormikRef.current.values, profileTemplateFields);
    const shippingFormData = withShippingAddress ? extractEscapedTemplateFields(shippingFormikRef.current.values, shippingTemplateFields) : undefined;    
    dispatch(saveGuest(formData, shippingFormData));    
  };

  const onAddressFormValidate = async (formikRef, formValid) => {
    const otherFormikRef = formikRef.current === profileFormikRef.current ? shippingFormikRef : profileFormikRef;
    const otherFormValid = !otherFormikRef.current || (await validateForm(otherFormikRef, true));

    if (formValid && otherFormValid)
      setShowValidation(false);
  };

  const onAddressOptionChange = async useSingleAddress => {
    if (!useSingleAddress)
      return;
    
    const profileFormValid = await validateForm(profileFormikRef, true);
    if (profileFormValid)
      setShowValidation(false);

  };
    
  return (
    <AddressStepSection
      className={styles.address}
      isGuest
      isCompleted={isCompleted}      
    >
      <GuestAddressStepBody
        profileFields={profileFields}
        profileTemplateFields={profileTemplateFields}
        shippingFields={shippingAddress && shippingAddress.address?.fields}
        shippingTemplateFields={shippingTemplateFields}
        onAddressFormSubmit={onAddressFormSubmit}
        onAddressFormValidate={onAddressFormValidate}
        profileFormikRef={profileFormikRef}
        shippingFormikRef={shippingFormikRef}
        submitFormOnBlur
        showValidation={showValidation}
        onAddressOptionChange={onAddressOptionChange}
      />
      {isShowExistingAccountPopup ?
        <ExistingAccountModal opened={opened} hide={toggle}>
          <ExistingAccountMessage />
        </ExistingAccountModal>
        : null}
      {isShowExistingGuestAccountPopup ?
        <ExistingGuestAccountModal opened={opened} hide={toggle}>
          <ExistingGuestAccountMessage />
        </ExistingGuestAccountModal>
        : null}
    </AddressStepSection>

  );
};

GuestAddressStep.propTypes = {
  info: PropTypes.shape({
    profileFields: PropTypes.object,
    profileTemplateFields: PropTypes.array,
    shippingTemplateFields: PropTypes.array,
    shippingAddress: PropTypes.shape({
      address: PropTypes.object,
    }),
  }),
  isCompleted: PropTypes.bool,
};

export default GuestAddressStep;

import styles from './header/Header.module.scss';
import { memo, useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import MobileHeaderTemplate from './header/MobileHeaderTemplate';
import StickyHeaderTemplate from './header/StickyHeaderTemplate';
import DesktopHeaderTemplate from './header/DesktopHeaderTemplate';
import withHeaderContext from './headerContext/withHeaderContext';
import { useEventObservable, scroll$ } from 'utils/rxjs';
import { useLayoutShifter, useResponsiveBreakpoints } from 'utils/layout';
import { joinClasses } from 'utils/helpers';
import { toggleOverflowAnchorState } from 'components/primitives/transitions';
import { Media } from 'components/responsive';

const ELEMENT_NAME = 'desktopHeader';
const SHIFT_TYPE = 'TOP'; /*:ShiftType*/

const Header = ({ isDesktopNavHovered, setDesktopNavHoverStatus }) => {
  const ref = useRef();
  const shiftValueRef = useRef(0);
  const [stickyOnDesktop, setStickyOnDesktop] = useState(false);
  const { xs, sm, md, lg, xl } = useResponsiveBreakpoints();
  const isDesktop = md || lg || xl;
  const { topShift, topShiftBlockHeight, updateElementsAffectingShiftData } = useLayoutShifter();

  const handleScroll = useCallback(() => {
    // StickyHeaderTemplate on desktop resolutions (lg or xl) should be shown when DesktopHeaderTemplate
    // is not in the viewport and desktop top navigation menu is not hovered
    const { pageYOffset } = window;

    if (stickyOnDesktop && pageYOffset <= shiftValueRef.current) {
      toggleOverflowAnchorState(true);
      setStickyOnDesktop(false);
      return;
    }

    if (isDesktopNavHovered)
      return;

    const isDesktopHeaderOutOfViewport = pageYOffset >= ref.current.offsetHeight;
    if (!stickyOnDesktop && isDesktopHeaderOutOfViewport) {
      handleSwitchToStickyHeader(ref.current, updateElementsAffectingShiftData);
   
         const  lookBookAvail = document.querySelector('.twoGrid');
        if (!(lookBookAvail) ) {
          setStickyOnDesktop(true);
        }   
    }
  }, [stickyOnDesktop, isDesktopNavHovered]);

  useEventObservable(scroll$, handleScroll, isDesktop, [stickyOnDesktop, isDesktopNavHovered]);

  useEffect(() => {
    ref.current.style.height = ''; // Reset header inline styled zero height
    shiftValueRef.current = stickyOnDesktop // Save current layout shift value
      ? topShift - topShiftBlockHeight
      : topShift;

    if (document.body.style.overflowAnchor !== '')
      setTimeout(toggleOverflowAnchorState, 25);

    // Fixes document.activeElement is not present on sticky header appearing when any desktop
    // header element was in focus in IE11. In other browsers document.activeElement is set to
    // the body element
    if (!document.activeElement)
      document.body.focus();

    return () => {
      if (!stickyOnDesktop)
        return;

      updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME); // Remove DesktopHeaderTemplate height from layout shift value
    };
  }, [stickyOnDesktop]);

  useEffect(() => toggleOverflowAnchorState, []);

  useEffect(() => {
    if (!!document.activeElement)
      return;

    // Fixes document.activeElement is not present on header type change if an element in previous rendered
    // type of header was in focus in IE11. In other browsers document.activeElement is set to the body element
    document.body.focus();
  }, [xs, sm, isDesktop, stickyOnDesktop]);

  useEffect(() => {
    if (!isDesktop) {
      // Reset sticky on desktop and desktop menu hover status on mobile and tablet
      updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME);
      setStickyOnDesktop(false);
      setDesktopNavHoverStatus(false);
      return;
    }
    // Set sticky header status depending on current scroll position for desktop resolutions
    setStickyOnDesktop(window.pageYOffset >= ref.current.offsetHeight);
  }, [isDesktop]);

  useEffect(() => {
    if (!isDesktop)
      return;

    if (isDesktopNavHovered) {
      const className = 'allow-root-overflow';
      document.documentElement.classList.add(className);

      return () => {
        document.documentElement.classList.remove(className);
      };
    }

    if (window.pageYOffset < ref.current.offsetHeight)
      return;

    handleSwitchToStickyHeader(ref.current, updateElementsAffectingShiftData);
    const  lookBookAvail = document.querySelector('.twoGrid');
    if (!(lookBookAvail) ) {
      setStickyOnDesktop(true);
    }
   
  }, [isDesktop, isDesktopNavHovered]);

  const headerClassName = joinClasses(
    isDesktop && !stickyOnDesktop && styles.desktop,
    xs && styles.mobile,
    isDesktop && isDesktopNavHovered && styles.navIsHovered,
  );
  useEffect(() => {
    const rightConAvail = document.getElementById('right-side-view');
    if (!rightConAvail) { 
     const body = document.body;
     body.classList.remove('look-book');
    }
  });

  return (
    <header id="header" className={headerClassName} ref={ref}>
      <Media at="xs">
        <MobileHeaderTemplate />
      </Media>
      <Media at="sm">
        <StickyHeaderTemplate noGutters />
      </Media>
      <Media greaterThanOrEqual="md">
        {stickyOnDesktop
          ? <StickyHeaderTemplate isDesktop noGutters={md} />
          : <DesktopHeaderTemplate />
        }
      </Media>
    </header>
  );
};

Header.propTypes = {
  isDesktopNavHovered: PropTypes.bool.isRequired,
  setDesktopNavHoverStatus: PropTypes.func.isRequired,
};

const MemoizedHeader = memo(Header);

export default withHeaderContext(MemoizedHeader);

function handleSwitchToStickyHeader(headerElement, updateElementsAffectingShiftData) {
  // Layout shift updated with DesktopHeaderTemplate height, header height temporarily set to 0
  // to prevent content jumping and preserve scroll position on StickyHeaderTemplate appearance
  updateElementsAffectingShiftData(SHIFT_TYPE, ELEMENT_NAME, headerElement.offsetHeight, true, false);
  headerElement.style.height = 0;
}

exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExistingGuestAccountModal_modal-container{max-width:520px;width:100%;margin:0 auto}.ExistingGuestAccountModal_modal-buttons-block{padding:1.5em 0;margin-top:1.5em}.ExistingGuestAccountModal_btn-confirm{margin:0 auto}", ""]);

// exports
exports.locals = {
	"modal-container": "ExistingGuestAccountModal_modal-container",
	"modalContainer": "ExistingGuestAccountModal_modal-container",
	"modal-buttons-block": "ExistingGuestAccountModal_modal-buttons-block",
	"modalButtonsBlock": "ExistingGuestAccountModal_modal-buttons-block",
	"btn-confirm": "ExistingGuestAccountModal_btn-confirm",
	"btnConfirm": "ExistingGuestAccountModal_btn-confirm"
};
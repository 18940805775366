exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ExistingAccountEmail_actions-list,.ExistingAccountEmail_description{word-break:break-word}@supports (-ms-ime-align:auto){.ExistingAccountEmail_actions-list,.ExistingAccountEmail_description{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ExistingAccountEmail_actions-list,.ExistingAccountEmail_description{word-wrap:break-word}}.ExistingAccountEmail_description{margin:0}.ExistingAccountEmail_actions-list{margin:0;padding:.75em 0;list-style-type:none}@supports (-ms-ime-align:auto){.ExistingAccountEmail_actions-list a,.ExistingAccountEmail_actions-list button{max-width:100%}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ExistingAccountEmail_actions-list a,.ExistingAccountEmail_actions-list button{max-width:100%}}.ExistingAccountEmail_link-placeholder{margin-bottom:.7em;width:12em}.ExistingAccountEmail_contact-us-link,.ExistingAccountEmail_home-link,.ExistingAccountEmail_my-account-link,.ExistingAccountEmail_order-link{\n  /*!*/}", ""]);

// exports
exports.locals = {
	"actions-list": "ExistingAccountEmail_actions-list",
	"actionsList": "ExistingAccountEmail_actions-list",
	"description": "ExistingAccountEmail_description",
	"link-placeholder": "ExistingAccountEmail_link-placeholder",
	"linkPlaceholder": "ExistingAccountEmail_link-placeholder",
	"contact-us-link": "ExistingAccountEmail_contact-us-link",
	"contactUsLink": "ExistingAccountEmail_contact-us-link",
	"home-link": "ExistingAccountEmail_home-link",
	"homeLink": "ExistingAccountEmail_home-link",
	"my-account-link": "ExistingAccountEmail_my-account-link",
	"myAccountLink": "ExistingAccountEmail_my-account-link",
	"order-link": "ExistingAccountEmail_order-link",
	"orderLink": "ExistingAccountEmail_order-link"
};
import { memo } from 'react';
import { useSanaTexts, RichText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { makeSimpleText, makeRichText } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';
import { Link } from 'components/primitives/links';

export default memo(() => {
  const [
    existingAccountTitle,    
  ] = useSanaTexts([
    'Registration_ExistingAccountTitle',    
  ]).texts;
  const loginLink = (
    <Link to={routesBuilder.forLogin}>
      <RichText textKey="ExistingGuestAccount_Login" />
    </Link>
  );
  
  return (
    <>
      <h2>{existingAccountTitle}</h2>
      <p >
        <RichText
          textKey="Registration_ExistingAccountDescription"
          formatWith={[loginLink]}
        />
      </p>      
    </>
  );
});
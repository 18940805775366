export const FLEET_ITEMS_RECEIVED = 'FLEET_ITEMS_RECEIVED';
export const fleetItemsReceived = ({ fleetItems }, { addFleetItems = [] }, append = false, quantityEditable = false) => ({
  type: FLEET_ITEMS_RECEIVED,
  payload: { fleetItems, addFleetItems,  append, quantityEditable },

});

export const FLEET_ITEMS_NEXT_RECEIVED = 'FLEET_ITEMS_NEXT_RECEIVED';
export const fleetItemsNextReceived = ({ fleetItems }, append = false, updated = false, isExistingUpdated = false) => ({
  type: FLEET_ITEMS_NEXT_RECEIVED,
  payload: { fleetItems, append, updated, isExistingUpdated },

});

export const FLEET_ITEMS_ADDING_RECEIVED = 'FLEET_ITEMS_ADDING_RECEIVED';
export const fleetItemsAddingReceived = ({ fleetItems }, append = false, updated = false, isExistingUpdated = false) => ({
  type: FLEET_ITEMS_ADDING_RECEIVED,
  payload: { fleetItems, append, updated, isExistingUpdated },

});

export const FLEET_ITEMS_REQUESTED = 'FLEET_ITEMS_REQUESTED';
export const requestFleetItems = (index = 0, size = 5, brand = '', model = '', machine = '', series = '') => ({
  type: FLEET_ITEMS_REQUESTED,
  payload: { index, size, brand , model , machine, series },
});

export const FLEET_ITEMS_REQUESTED_AFTER_UPDATE = 'FLEET_ITEMS_REQUESTED_AFTER_UPDATE';
export const requestFleetItemsAfterUpdate = (index = 0, size = 5, brand = '', model = '', machine = '', series = '' ) => ({
  type: FLEET_ITEMS_REQUESTED_AFTER_UPDATE,
  payload: { index, size, brand, model, machine,series },
});

export const FLEET_ITEMS_REQUESTED_AFTER_ADDING = 'FLEET_ITEMS_REQUESTED_AFTER_ADDING';
export const requestFleetItemsAfterAdding = (isExistingUpdated = false, index = 0, size = 5, brand = '', model = '', machine = '', series = '' ) => ({
  type: FLEET_ITEMS_REQUESTED_AFTER_ADDING,
  payload: { isExistingUpdated, index, size, brand, model, machine, series},
});

export const FLEET_ITEMS_DELETE_REQUEST = 'FLEET_ITEMS_DELETE_REQUEST';
export const deleteFleetItemsRequest = (brand = '', model = '', machine = '',series='', id) => ({
  type: FLEET_ITEMS_DELETE_REQUEST,
  payload: { brand, model, machine,series, id },
});

export const FLEET_ITEMS_DELETED = 'FLEET_ITEMS_DELETED';
export const deleteFleetItems = ({ fleetItems }, brand = '', model = '', machine = '', series='', id ) => ({
  type: FLEET_ITEMS_DELETED,
  payload: { fleetItems, brand, model, machine,series, id },
});

export const FLEET_ITEMS_EDIT_REQUESTED = 'FLEET_ITEMS_EDIT_REQUESTED';
export const editFleetItem = (id, quantityEditable = false) => ({
  type: FLEET_ITEMS_EDIT_REQUESTED,
  payload: { id , quantityEditable },
});

export const FLEET_ITEMS_UPDATED = 'FLEET_ITEMS_UPDATED';
export const updateFleetItems = (id, brand = '', model = '', machine = '',series='', quantity = '') => ({
  type: FLEET_ITEMS_UPDATED,
  payload: { id,brand, model, machine,series, quantity },
});

export const FLEET_ITEMS_ADD = 'FLEET_ITEMS_ADD';
export const addFleetItems = (brand = '', model = '', machine = '',series='', quantity = '') => ({
  type: FLEET_ITEMS_ADD,
  payload: { brand, model, machine, series, quantity },
});

export const FLEET_ITEMS_AFTER_UPDATED = 'FLEET_ITEMS_AFTER_UPDATED';
export const afterUpdateFleetItems = (id, brand = '', model = '', machine = '', quantity = '') => ({
  type: FLEET_ITEMS_AFTER_UPDATED,
  payload: { id, brand, model, machine, quantity },
});

export const FLEET_ITEMS_SELECTED = 'FLEET_ITEMS_SELECTED';
export const filterFleetItems = (brand = '', machine = '',series = '', model = '') => ({
  type: FLEET_ITEMS_SELECTED,
  payload: { brand, model, machine, series },
});

export const FLEET_ITEMS_AFTER_SELECTED = 'FLEET_ITEMS_AFTER_SELECTED';
export const onFilterFleetItems = addFleetItems => ({
  type: FLEET_ITEMS_AFTER_SELECTED,
  payload: { addFleetItems },
});

export const FLEET_ITEMS_AFTER_ADDED = 'FLEET_ITEMS_AFTER_ADDED';
export const afterAddFleetItems = addFleetItems => ({
  type: FLEET_ITEMS_AFTER_ADDED,
  payload: { addFleetItems },
});

export const FLEET_ITEMS_REQUESTED_SET_DEFAULT = 'FLEET_ITEMS_REQUESTED_SET_DEFAULT';
export const fleetItemsSetDefault = fleetItemsSetDefault => ({
  type: FLEET_ITEMS_REQUESTED_SET_DEFAULT,
  payload: { fleetItemsSetDefault },
});

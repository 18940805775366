import { PageComponentNames } from 'behavior/pages';
import createRenderer from './createSystemRenderer';
import Login from 'components/objects/login';
import ForgotPassword from 'components/objects/forgotPassword';
import ResetPassword from 'components/objects/resetPassword';
import Registration from 'components/objects/registration';
import Represent from 'components/objects/representation';
import ContactUs from 'components/objects/contactUs';
import ExistingAccountEmail from 'components/objects/profile/existingAccountEmail';//[107333][JMC] 3.9 Customer Registration – Avoid duplicate accounts OPTION 2
import ExistingAccountPhone from 'components/objects/profile/existingAccountPhone';
import AccountVerification from 'components/objects/profile/accountVerification';
import NotFound from 'components/objects/notFound';
import { Subscribe, UnSubscribe } from 'components/objects/newsletter/page';
import profile from 'components/objects/profile';
import LastViewedProducts from 'components/objects/productList/lastViewed';
import {
  default as checkoutRenderer,
  orderSubmitRenderer,
  orderFailedRenderer,
  orderCancelledRenderer,
  paymentErrorRenderer,
} from 'components/objects/checkout/';
import { invoicePaymentRenderer } from 'components/objects/invoicePayment';
import {
  orderPaymentRenderer,
  paymentSuccessfulRenderer,
  paymentCancelledRenderer,
  paymentFailedRenderer,
} from 'components/objects/orderPayment';

export default {
  login: createRenderer('Login_Title', Login),
  forgotPassword: createRenderer(null, ForgotPassword),
  [PageComponentNames.ResetPassword]: createRenderer(null, ResetPassword),
  ...profile,
  [PageComponentNames.Represent]: createRenderer('RepresentCustomer_Title', Represent),
  contactUs: createRenderer('ContactUs_Title', ContactUs),
  [PageComponentNames.ExistingAccountEmail]: createRenderer(null, ExistingAccountEmail),//[107333][JMC] 3.9 Customer Registration – Avoid duplicate accounts OPTION 2
  [PageComponentNames.ExistingAccountPhone]: createRenderer(null, ExistingAccountPhone),
  [PageComponentNames.AccountVerification]: createRenderer(null, AccountVerification),
  [PageComponentNames.NotFound]: createRenderer('PageNotFound_Title', NotFound),
  subscribe: createRenderer('NewsletterSubscribe_Title', Subscribe),
  unsubscribe: createRenderer('NewsletterUnsubscribe_Title', UnSubscribe),
  [PageComponentNames.LastViewedProducts]: createRenderer('LastViewedProducts_Header', LastViewedProducts),
  [PageComponentNames.Registration]: createRenderer('CreateAccount_MainHeader', Registration),
  [PageComponentNames.Checkout]: checkoutRenderer,
  [PageComponentNames.OrderSubmit]: orderSubmitRenderer,
  [PageComponentNames.OrderFailed]: orderFailedRenderer,
  [PageComponentNames.OrderCancelled]: orderCancelledRenderer,
  [PageComponentNames.PaymentError]: paymentErrorRenderer,
  [PageComponentNames.InvoicePayment]: invoicePaymentRenderer,
  [PageComponentNames.OrderPayment]: orderPaymentRenderer,
  [PageComponentNames.PaymentSuccessful]: paymentSuccessfulRenderer,
  [PageComponentNames.PaymentCancelled]: paymentCancelledRenderer,
  [PageComponentNames.PaymentFailed]: paymentFailedRenderer,
};

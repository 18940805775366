import { combineEpics, bufferBatchForLoading } from 'utils/rxjs';
import { ofType } from 'redux-observable';
import { of, Subject, NEVER, EMPTY, merge, concat, identity, timer, asapScheduler } from 'rxjs';
import {
  map, tap,
  switchMap, mergeMap, mergeMapTo,
  filter, distinct,
  pluck, catchError,
  first, switchMapTo,
  takeUntil, startWith,
  delayWhen, timeoutWith, observeOn,
} from 'rxjs/operators';
import {
  USER_LOGIN, authenticated, USER_LOGOUT, USER_RELOAD,
  USER_ABILITIES_REQUESTED, userAbilitiesLoaded, USER_ABILITIES_LOADED,
  REPRESENT_CUSTOMER, USER_REGISTER, USER_CREATE_PROSPECT,
  logout, representCustomer, impersonationFailed,
} from './actions';
import { USER_ANON_EXPIRED, anonUserExpired } from './broadcastActions';
import { viewerChanged, navigateTo } from 'behavior/events';
import { APP_INIT, APP_INIT_HYDRATE } from 'behavior/app';
import {
  loginMutation,
  createViewerQuery,
  loadAbilitiesQuery,
  representMutation,
  registrationMutation,
  createProspectMutation,
} from './queries';
import { ShopAccountTypes, UserTypes, AbilityState, AbilityTo } from './constants';
import { navigateToPrevious, reloadLocation, redirectTo } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { routesBuilder, RouteName } from 'routes';
import { registrationProcessed } from 'behavior/pages/registration';
import { createProspectProcessed } from 'behavior/pages/createProspect';
import { handleToken, createUserData, createMapLoginResult, convertAbilities } from './helpers';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { visibility$ } from 'utils/rxjs/eventsObservables';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { requestRoute } from 'behavior/route';
import { getBackToFromUrl } from 'behavior/pages/helpers';
import { ADMIN_IMPERSONATION_STOPPED, stop as stopAdminImpersonation } from 'behavior/tools/impersonation';
import { skipIfPreviewWithToast } from 'behavior/preview';

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

const expirations$ = new Subject();

const authenticationEpic = (action$, state$, deps) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const mapLoginResult = createMapLoginResult(state$, deps, expirations$);

  return action$.pipe(
    ofType(USER_LOGIN),
    switchMap(action => deps.api.graphApi(loginMutation, {
      input: action.payload.authData,
      keys: getAbilitiesKeys(state$),
    }, { retries: 0, useCookies: true }).pipe(
      mergeMap(result => mapLoginResult(action.payload.authData.email, result.profile.login, result.viewer)),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unsetLoading)),
      retryWithToast(action$, deps.logger, _ => of(unsetLoading)),
      takeUntil(locationChanged$),
      startWith(setLoading),
    )),
  );
};

const registrationEpic = (action$, state$, deps) => {
  const mapLoginResult = createMapLoginResult(state$, deps, expirations$);

  return action$.pipe(
    ofType(USER_REGISTER),
    switchMap(({ payload: { registrationData } }) => deps.api.graphApi(registrationMutation, {
      input: registrationData,
      loginInfo: {
        email: registrationData.email,
        password: registrationData.password,
        persistent: false,
      },
      keys: getAbilitiesKeys(state$),
    }, { retries: 0, useCookies: true }).pipe(
      mergeMap(result => {
        const {
          profile: { register: registrationResult, login: loginResult },
          viewer: viewerResult,
        } = result;

        const unlockFormAction = registrationResult.isRegistered
          ? EMPTY
          : of(unlockForm(FormLockKeys.Registration));

        //[107333][JMC] 3.9 Customer Registration – Avoid duplicate accounts OPTION 2
        if (typeof loginResult == 'undefined') {
          return concat(
            of(registrationProcessed(registrationResult), unsetLoadingIndicator()),
            unlockFormAction,
          );
        }

        return concat(
          of(registrationProcessed(registrationResult)),
          unlockFormAction,         
          mapLoginResult(registrationData.email, loginResult, viewerResult),
        );
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(registrationProcessed({ invalidInput: true }), unlockForm(FormLockKeys.Registration), unsetLoading)),
      retryWithToast(action$, deps.logger, _ => of(registrationProcessed({}), unlockForm(FormLockKeys.Registration), unsetLoading)),
      startWith(setLoading),
    )),
  );
};

const createProspectEpic = (action$, state$, deps) => {
  return action$.pipe(
    ofType(USER_CREATE_PROSPECT),
    pluck('payload', 'prospectData'),
    switchMap(prospectData => deps.api.graphApi(createProspectMutation, {
      input: prospectData,
      keys: getAbilitiesKeys(state$),
    }, { retries: 0, useCookies: true }).pipe(
      pluck('profile', 'createProspect'),
      mergeMap(createProspectResult => {
        if (createProspectResult.isCreated && createProspectResult.contactId) {
          return of(representCustomer(createProspectResult.contactId, ShopAccountTypes.Contact));
        }

        return createProspectResult.isCreated
          ? of(createProspectProcessed(createProspectResult))
          : of(createProspectProcessed(createProspectResult), unlockForm(FormLockKeys.CreateProspect));
      }),
      catchApiErrorWithToast(['INVALID_INPUT'], of(createProspectProcessed({}), unlockForm(FormLockKeys.CreateProspect), unsetLoading)),
      retryWithToast(action$, deps.logger, _ => of(createProspectProcessed({}), unlockForm(FormLockKeys.CreateProspect), unsetLoading)),
      startWith(setLoading),
    )),
  );
};

const representationEpic = (action$, state$, { api, scope, logger }) => action$.pipe(
  ofType(REPRESENT_CUSTOMER),
  skipIfPreviewWithToast(state$, { api }),
  switchMap(action => api.graphApi(representMutation, {
    id: action.payload.id,
    shopAccountType: action.payload.shopAccountType,
    keys: getAbilitiesKeys(state$),
  }, { retries: 0, useCookies: true }).pipe(
    filter(r => r.profile.impersonation),
    mergeMap(result => {
      const impersonationResult = result.profile.impersonation.represent;
      if (impersonationResult.failureText) {
        return of(impersonationFailed());
      }

      if (impersonationResult.token) {
        expirations$.next(impersonationResult.token.expiration);
        api.setAuthToken(impersonationResult.token.value);

        const data = createUserData(result.viewer, true);
        const currentUser = state$.value.user;
        data.email = currentUser.email;
        data.type = currentUser.type;

        if (action.payload.redirectBack) {
          let navigateAction;
          if (action.payload.shopAccountType === ShopAccountTypes.Contact)
            navigateAction = navigateTo(routesBuilder.forHome());
          else {
            const backTo = getBackToFromUrl(scope);
            navigateAction = backTo
              ? navigateTo(undefined, backTo.url)
              : navigateToPrevious([RouteName.Represent]);
          }

          return of(authenticated(data), viewerChanged(), navigateAction);
        }

        return of(authenticated(data), viewerChanged(), reloadLocation());
      }

      return action.payload.redirectBack ? of(navigateToPrevious()) : EMPTY;
    }),
    retryWithToast(action$, logger, _ => EMPTY),
  )),
);

const checkAuthToken = (action$, state$, { api, logger, scope }) => {
  const isClient = scope === 'CLIENT';
  const actions$ = action$.pipe(ofType(APP_INIT, USER_RELOAD));
  let source$ = actions$;

  if (api.authChanges$) {
    source$ = merge(
      actions$,
      api.authChanges$.pipe(
        switchMapTo(visibility$.pipe(
          first(identity),
        )),
      ),
    );
  }

  return source$.pipe(
    switchMap(action => api.graphApi(createViewerQuery(isClient, true), { keys: getAbilitiesKeys(state$) }, { useCookies: isClient }).pipe(
      isClient ? handleToken(api, expirations$, false) : identity,
      mergeMap(({ viewer }) => {
        delete viewer.token;

        const currentUser = state$.value.user;
        const newUser = createUserData(viewer, isAuthenticated(viewer));

        if (action.type === USER_RELOAD)
          return [authenticated(newUser), viewerChanged(), reloadLocation()];

        if (!currentUser.initialized || (currentUser.id === newUser.id && currentUser.customer?.id === newUser.customer?.id))
          return [authenticated(newUser)];

        return [authenticated(newUser), viewerChanged()];
      }),
      catchError(e => {
        logger.error(e);
        isClient && expirations$.next(null);
        api.setAuthToken(null);
        return NEVER;
      }),
    )),
  );
};

const renewToken = (action$, state$, { api, logger, scope }) => {
  if (scope !== 'CLIENT')
    return EMPTY;

  const maxExpirationTimeout = 24 * 60 * 60 * 1000; // 1 day.

  return expirations$.pipe(
    observeOn(asapScheduler),
    switchMap(expiration => {
      if (!expiration)
        return EMPTY;

      const now = Date.now();
      const expirationDate = new Date(expiration);
      const timeout = Math.min(expirationDate - now, maxExpirationTimeout);

      return timer(timeout / 2).pipe(
        delayWhen(_ => action$),
        mergeMap(_ => api.graphApi(createViewerQuery(true, true), { keys: getAbilitiesKeys(state$) }, { useCookies: true }).pipe(
          handleToken(api, expirations$),
          map(({ viewer }) =>
            authenticated(createUserData(viewer, isAuthenticated(viewer))),
          ),
          catchError(e => {
            logger.error(e);
            api.setAuthToken(null);
            return of(logout());
          }),
        )),
        timeoutWith(timeout, of(logout())),
        takeUntil(merge(action$.pipe(ofType(USER_LOGIN, USER_LOGOUT)), api.authChanges$)),
      );
    }),
  );
};

const loadAuthToken = (action$, state$, { api }) => action$.pipe(
  ofType(APP_INIT_HYDRATE),
  switchMap(_ => api.graphApi(createViewerQuery(true), undefined, { useCookies: true }).pipe(
    handleToken(api, expirations$),
    filter(({ viewer }) => viewer.id !== state$.value.user.id),
    mergeMap(({ viewer }) => {
      return of(
        authenticated(createUserData(viewer, isAuthenticated(viewer))),
        viewerChanged(),
      );
    }),
  )),
);

const unauthenticationEpic = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  return action$.pipe(
    ofType(USER_LOGOUT, ADMIN_IMPERSONATION_STOPPED),
    switchMap(action => {
      if (state$.value.routing.routeData?.params?.previewToken != null)
        return of(navigateTo(routesBuilder.forHome()));

      const isLogoutAction = action.type === USER_LOGOUT || action.payload.isLogout;

      if (isLogoutAction && dependencies.toolsStorage.toolEnabled('Impersonate'))
        return of(stopAdminImpersonation(true));

      const requestOptions = {
        useCookies: true,
        authToken: isLogoutAction ? null : undefined,
      };

      return api.graphApi(createViewerQuery(true, true), { keys: getAbilitiesKeys(state$) }, requestOptions).pipe(
        handleToken(api, expirations$),
        mergeMap(({ viewer }) => {
          const userData = createUserData(viewer, isAuthenticated(viewer));
          const authentication = state$.value.page.authentication;

          if (isLogoutAction && !state$.value.user.id)
            dependencies.broadcast.dispatch(anonUserExpired());

          if (authentication && isLogoutAction) {
            let redirectToLogin = authentication.required;
            if (!redirectToLogin && authentication.abilities) {
              redirectToLogin = authentication.abilities
                .some(ability => userData.abilities[ability] === AbilityState.Unauthorized);
            }

            if (redirectToLogin) {
              const loginRoute = routesBuilder.forLogin();
              return requestRoute(loginRoute, state$, dependencies).pipe(
                mergeMap(path => merge(
                  of(redirectTo(path, 302, loginRoute)),
                  action$.pipe(
                    first(),
                    mergeMapTo(of(authenticated(userData), viewerChanged())),
                  ),
                )),
              );
            }
          }

          return of(authenticated(userData), viewerChanged(), reloadLocation());
        }),
        catchError(e => {
          logger.error(e);
          return NEVER;
        }),
      );
    }),
  );
};

const mostUsedAbities = [
  AbilityTo.ViewCatalog,
  AbilityTo.ViewPrices,
  AbilityTo.ViewStock,
  AbilityTo.ViewUnitOfMeasure,
  AbilityTo.ViewProductSuggestions,
  AbilityTo.ViewMyAccountPage,
  AbilityTo.OrderProducts,
  AbilityTo.CreateOrder,
  AbilityTo.CreateQuote,
  AbilityTo.CompareProducts,
  AbilityTo.SubscribeToNewsletter,
  AbilityTo.UseWishlist,
];

function userAbilitiesEpic(action$, state$, { api, completePendingActions$ }) {
  const subject = new Subject();
  userAbilitiesEpic.pushAbility = (key, state) => subject.next(userAbilitiesLoaded({ [key]: state }));

  return merge(
    subject,
    action$.pipe(
      ofType(USER_ABILITIES_REQUESTED),
      mergeMap(action => action.payload),
      distinct(undefined, action$.pipe(ofType(USER_ABILITIES_LOADED))),
      bufferBatchForLoading(completePendingActions$),
      map(keys => filterLoadedAbilities(state$, keys)),
      filter(keys => keys.length),
      mergeMap(keys => api.graphApi(loadAbilitiesQuery, { keys }).pipe(
        pluck('viewer', 'abilities'),
        map(abilities => userAbilitiesLoaded(convertAbilities(abilities))),
      )),
    ),
  );
}

function filterLoadedAbilities(state$, keys) {
  const { initialized, expiredAbilities, abilities } = state$.value.user;
  return keys.filter(key => {
    if (abilities[key] && !expiredAbilities.includes(key))
      return false;

    if (!initialized && mostUsedAbities.includes(key))
      return false;

    return true;
  });
}

const broadcastActionsEpic = (_action$, _state$, dependencies) => dependencies.broadcast.action$.pipe(ofType(USER_ANON_EXPIRED));

export default combineEpics(
  checkAuthToken,
  loadAuthToken,
  authenticationEpic,
  registrationEpic,
  representationEpic,
  unauthenticationEpic,
  userAbilitiesEpic,
  renewToken,
  createProspectEpic,
  broadcastActionsEpic,
);

export function requestAbility(key, state$, { api }) {
  const { abilities, expiredAbilities } = state$.value.user;

  const existingAbility = abilities[key];
  if (existingAbility && !expiredAbilities.includes(key))
    return of(existingAbility);

  return api.graphApi(loadAbilitiesQuery, { keys: [key] }).pipe(
    pluck('viewer', 'abilities', '0', 'state'),
    tap(state => userAbilitiesEpic.pushAbility(key, state)),
  );
}

function getAbilitiesKeys(state$) {
  const abilitiesFromState = Object.keys(state$.value.user.abilities);
  if (abilitiesFromState.length)
    return abilitiesFromState;

  return mostUsedAbities;
}

function isAuthenticated(viewer) {
  return viewer.type === UserTypes.Registered || viewer.type === UserTypes.Admin;
}

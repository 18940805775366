import {
  FLEET_ITEMS_RECEIVED,
  FLEET_ITEMS_DELETED,
  FLEET_ITEMS_UPDATED,
  FLEET_ITEMS_AFTER_UPDATED,
  FLEET_ITEMS_AFTER_ADDED,
  FLEET_ITEMS_REQUESTED,
  FLEET_ITEMS_NEXT_RECEIVED,
  FLEET_ITEMS_AFTER_SELECTED,
  FLEET_ITEMS_REQUESTED_AFTER_UPDATE,
  FLEET_ITEMS_REQUESTED_SET_DEFAULT,
  FLEET_ITEMS_REQUESTED_AFTER_ADDING,
  FLEET_ITEMS_ADDING_RECEIVED,
} from 'behavior/pages/fleetItems/actions';
import { createReducer } from 'utils/redux';

const initialState = {
  items: null,
  status: null,
  savingResult: null,
  isUpdated: false,
  isExistingUpdated: false,
  brandCategoryOption: null,
  modelCategoryOption: null,
  machineCategoryOption: null,
  seriesCategoryOption: null,
  isSearchFailed: false,
  isDeleted: false,
  isSearchSuccess: false,
  url: null, 
};

export default createReducer(initialState, {
  [FLEET_ITEMS_RECEIVED]: onFleetItemReceived,
  [FLEET_ITEMS_NEXT_RECEIVED]: onFleetItemNextReceived,
  [FLEET_ITEMS_ADDING_RECEIVED]: onFleetItemAddingReceived,
  [FLEET_ITEMS_DELETED]: onFleetItemDeleted,
  [FLEET_ITEMS_UPDATED]: onFleetItemUpdated,
  [FLEET_ITEMS_AFTER_UPDATED]: afterUpdateFleetItems,
  [FLEET_ITEMS_AFTER_ADDED]: fleetItemsAdded,
  [FLEET_ITEMS_REQUESTED]: onFleetItemsRequested,
  [FLEET_ITEMS_AFTER_SELECTED]: onFleetItemsSelected,
  [FLEET_ITEMS_REQUESTED_AFTER_UPDATE]: onRequestAfterUpdate,
  [FLEET_ITEMS_REQUESTED_SET_DEFAULT]: fleetItemsSetDefault,
  [FLEET_ITEMS_REQUESTED_AFTER_ADDING]: fleetItemAfterAdded,

});

function onFleetItemsRequested(state, action) {
  return {
    ...state,
    isSearchFailed : false,
    isDeleted: false,
    isUpdated: false,
    isSearchSuccess: false,
    isExistingUpdated: false,
  };
}

function onRequestAfterUpdate(state, action) {
  return {
    ...state,
    isSearchFailed: false,
    isDeleted: false,
    isUpdated: true,
    isSearchSuccess: false,
    isExistingUpdated: false,
  };
}

function onFleetItemReceived(state, action) {
  const fleetItems = action.payload.append && action.payload.fleetItems ? state.items.concat(action.payload.fleetItems) : action.payload.fleetItems;
  const categoryList = action.payload.addFleetItems;
  if (fleetItems.length === 0) {
    state.isSearchFailed = true;
  }

  return {
    ...state,
    items: fleetItems,
    isSearchFailed: false,
    isDeleted: false,
    isUpdated: false,
    isExistingUpdated: false,
    brandCategoryOption: categoryList[0] ? categoryList[0].brandList : [],
    modelCategoryOption: categoryList[0] ? categoryList[0].modelList : [],
    machineCategoryOption: categoryList[0] ? categoryList[0].machineList: [],
    seriesCategoryOption: categoryList[0] ? categoryList[0].seriesList: [],
  };
}

function onFleetItemNextReceived(state, action) {
  const fleetItems = action.payload.append && action.payload.fleetItems ? state.items.concat(action.payload.fleetItems) : action.payload.fleetItems;
  const empty = fleetItems.length ? false : true;
  state.isSearchSuccess = !action.payload.append;
  return {
    ...state,
    isDeleted: false,
    isUpdated: false,
    items: empty ? state.items : fleetItems,
    isSearchFailed : empty,
  };
}

function onFleetItemAddingReceived(state, action) {
  const fleetItems = action.payload.append && action.payload.fleetItems ? state.items.concat(action.payload.fleetItems) : action.payload.fleetItems;
  const empty = fleetItems.length ? false : true;
  state.isSearchSuccess = !action.payload.append;
  return {
    ...state,
    isDeleted: false,
    isUpdated: false,
    items: empty ? state.items : fleetItems,
    isSearchFailed: empty,
  };
}

function fleetItemAfterAdded(state, action) {
  state.isExistingUpdated = action.payload.isExistingUpdated;
  state.isUpdated = !action.payload.isExistingUpdated;
  return {
    ...state,
    isDeleted: false,
   // items: fleetItems,
  };
}

function onFleetItemDeleted(state, action) {
  const id = action.payload.id;
  const fleetItems = state.items.filter(f => (f.id !== id ));
  return {
    ...state,
    items: fleetItems,
    isDeleted: true,
    isUpdated: false,
    isExistingUpdated: false,
  };
}

function onFleetItemUpdated(state, action) {
    const id = action.payload.id;
    const fleetItem = state.items.filter(f => (f.id === id));
    fleetItem.quantity = action.payload.quantity;

    return {
        ...state,
        items: state.items,
      isUpdated: false,
      isDeleted: false,
      isExistingUpdated: false,
    };
}

function afterUpdateFleetItems(state, action) {
  const id = action.payload.id;
  const fleetItem = state.items.filter(f => (f.id === id));
  fleetItem.quantity = action.payload.quantity;

  return {
    ...state,
    items: state.items,
    isUpdated: true,
    isDeleted: false,
    isExistingUpdated: false,
  };
}

function fleetItemsAdded(state, action) {
  const fleetItemsAdded = action.payload.addFleetItems ? action.payload.addFleetItems : [];
  return {
    ...state,
    isUpdated: false,
    isDeleted: false,
    isExistingUpdated: false,
    brandCategoryOption: fleetItemsAdded,
  };
}

function onFleetItemsSelected(state, action) {
  const categoryList = action.payload.addFleetItems;
  return {
    ...state,
    items: state.items,
    isUpdated: false,
    isDeleted: false,
    isExistingUpdated: false,
    brandCategoryOption: categoryList[0] ? categoryList[0].brandList : [],
    modelCategoryOption: categoryList[0] ? categoryList[0].modelList : [],
    machineCategoryOption: categoryList[0] ? categoryList[0].machineList : [],
    seriesCategoryOption: categoryList[0] ? categoryList[0].seriesList : [],
    url: categoryList[0] ? categoryList[0].url : null,
  };
}

function fleetItemsSetDefault(state, action) {
  return {
    ...state,
    isUpdated: false,
    isDeleted: false,
    isExistingUpdated: false,
  };
}

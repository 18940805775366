import styles from './OrderTemplates.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useRef, memo, useState, useCallback, useEffect } from 'react';
import { Modal, useModal } from 'components/objects/modals';
import { Button, SanaButton } from 'components/primitives/buttons';
import { RichText, useSimpleTexts } from 'components/sanaText';
import { filterFleetItems, addFleetItems } from 'behavior/pages/fleetItems';
import { Select } from 'components/primitives/form';
import { useDispatch } from 'react-redux';
import { Input } from 'components/primitives/form';
import { DangerAlert } from 'components/primitives/alerts';

const AddFleetItem = ({ brandCategoryOption, machineCategoryOption, modelCategoryOption, seriesCategoryOption, isExistingUpdated }) => {

  const { opened, show, hide } = useModal();
  const { texts } = useAddFleetItemTexts();
  const dispatch = useDispatch();
  const [isCleared, setCleared] = useState(false);

  const brandValue = brandCategoryOption.length === 1 ? brandCategoryOption[0].id : '';
  const [brand, setBrand] = useState({ brandValue });
  useEffect(() => {
    setCleared(false);
    if (brandValue !== '') {
      setBrand(brandValue);
    }
  }, [brandValue && brandValue !== '']);

  const machinValue = machineCategoryOption.length === 1 ? machineCategoryOption[0].id : '';
  const [machine, setMachine] = useState({ machinValue });
  useEffect(() => {
    setCleared(false);
    if (machinValue !== '') {
      setMachine(machinValue);
    }
  }, [machinValue && machinValue !== '']);

  const seriesValue = seriesCategoryOption.length === 1 ? seriesCategoryOption[0].id : '';
  const [series, setSeries] = useState({ seriesValue });
  useEffect(() => {
    setCleared(false);
    if (seriesValue !== '') {
      setSeries(seriesValue);
    }
  }, [seriesValue && seriesValue !== '']);

  const [model, setModel] = useState('');
  const modelValue = modelCategoryOption.length === 1 ? modelCategoryOption[0].id : '';

  useEffect(() => {
    setCleared(false);
    if (modelValue && modelValue !== '') {
      setModel(modelValue);
    }
  }, [modelValue && modelValue !== '']);

  const [quantity, setQuantity] = useState(1);
  const [showValidation, setShowValidation] = useState(false);
  const inputRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();

    if (isCleared)
      return;
    //const brand = e.target.elements && e.target.elements['brandOption'].value;
    //const model = e.target.elements && e.target.elements['modelOption'].value;
    //const machine = e.target.elements && e.target.elements['machineOption'].value;
    //const quantity = e.target.elements && e.target.elements['quantity'].value;
    const form = document.forms['AddFleetItemForm'];
    const machine = form && form.elements['machineOption'].value;
    const model = form && form.elements['modelOption'].value;
    const brand = form && form.elements['brandOption'].value;
    const series = form && form.elements['seriesOption'].value;

    if (!brand || !model || !machine || !series || !quantity) {
      setShowValidation(true);
      return;
    }
    else {
      setShowValidation(false);
      dispatch(addFleetItems(brand, model, machine,series, quantity));
    }
  }

  function handleClearSelection() {

    setCleared(true);
    setBrand('');
    setMachine('');
    setModel('');
    setSeries('');
    dispatch(filterFleetItems('', '', '', ''));
    setShowValidation(false);
    return;
  }

  const BrandChange = useCallback(value => {
    setBrand(value);
    const form = document.forms['AddFleetItemForm'];
    const machine = form && form.elements['machineOption'].value;
    const model = form && form.elements['modelOption'].value;
    const series = form && form.elements['seriesOption'].value;
    dispatch(filterFleetItems(value, machine, series, model));
    return;
  }, []);

  const MachineChange = useCallback(value => {
    setMachine(value);
    const form = document.forms['AddFleetItemForm'];
    const brand = form && form.elements['brandOption'].value;
    const model = form && form.elements['modelOption'].value;
    const series = form && form.elements['seriesOption'].value;
    dispatch(filterFleetItems(brand, value, series, model));
    return;
  }, []);

  const SeriesChange = useCallback(value => {
    setSeries(value);
    const form = document.forms['AddFleetItemForm'];
    const machine = form && form.elements['machineOption'].value;
    const brand = form && form.elements['brandOption'].value;
    const model = form && form.elements['modelOption'].value;
    dispatch(filterFleetItems(brand, machine, value, model));
    return;
  }, []);

  const ModelChange = useCallback(value => {
    setModel(value);
    const form = document.forms['AddFleetItemForm'];
    const brand = form && form.elements['brandOption'].value;
    const machine = form && form.elements['machineOption'].value;
    const series = form && form.elements['seriesOption'].value;
    dispatch(filterFleetItems(brand, machine, series, value));
    return;
  }, [model]);

  function QuantityChange(e) {
    setQuantity(e.target.value);
  }

  const selectableFieldOptions = brandCategoryOption.length > 1 ? [{ name: texts.selectBrand, value: null }, ...brandCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [...brandCategoryOption.map(c => ({ name: c.name, value: c.id, selected: true }))];
  const selectableFieldOptions1 = machineCategoryOption.length > 1 ? [{ name: texts.selectMachine, value: null }, ...machineCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [...machineCategoryOption.map(c => ({ name: c.name, value: c.id, selected: true }))];
  const selectableFieldOptions2 = seriesCategoryOption.length > 1 ? [{ name: texts.selectSeries, value: null }, ...seriesCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [...seriesCategoryOption.map(c => ({ name: c.name, value: c.id, selected: true }))];
  const selectableFieldOptions3 = modelCategoryOption.length > 1 ? [{ name: texts.selectModel, value: null }, ...modelCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [...modelCategoryOption.map(c => ({ name: c.name, value: c.id, selected: true }))];

  const handleClick = () => {

    show();
    handleClearSelection();
    setCleared(false);
    setQuantity(1);
  };

  return (
    <>
      <div className="Grid_row SanaForm_form-row">
        <div className="Grid_col-12">
          <SanaButton textKey={texts.addFleetItemBtn} className="test margin-top-15" onClick={handleClick} />
        </div>
      </div>

      <Modal opened={opened} hide={hide} className="">
      <div className=" Grid_col-6 center-align add-fleet ">
        <h2 className="">{texts.addPopupHeader}</h2>
        <div>
          <h4 className="">{texts.popupDescription}</h4>
        </div>

        <form
          name="AddFleetItemForm"
          onSubmit={handleSubmit}
        >
          <div>
            <DangerAlert style={{ display: showValidation ? 'block' : 'none' }}>{texts.validation}</DangerAlert>
          </div>
          <div className="Grid_row">
            <div className="add-fleet-main-wrapper">
              <div className="add-fleet-wrapper">
                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="machineId">
                      {texts.brand}

                    </label>
                  </div>
                  <div className={styles.fullWidth}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions}
                      onChange={BrandChange}
                      value={brand}
                    />
                  </div>

                </div>
                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="machineId">

                      {texts.machine}
                    </label>
                  </div>
                  <div className={styles.fullWidth}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions1}
                      onChange={MachineChange}
                      value={machine}
                    />
                  </div>
                </div>
              </div>

              <div className="add-fleet-wrapper">

                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="machineId">{texts.series}</label>
                  </div>
                  <div className={styles.fullWidth}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions2}
                      onChange={SeriesChange}
                      value={series}
                    />
                  </div>

                </div>

                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="seriesId">{texts.model}</label>
                  </div>
                  <div className={styles.fullWidth}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions3}
                      onChange={ModelChange}
                      value={model}
                    />
                  </div>

                </div>
                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="machineId">{texts.quantity}</label>
                  </div>
                  <div className={`${styles.fullWidth} quantity-input`}>
                    <Input inputRef={inputRef} value={quantity} type="search" name="quantity" id="quantity" min="1" onChange={QuantityChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row">
              <div style={{ display: 'none' }}>
                <Input inputRef={inputRef} value={brand} type="search" name="brandOption" id="brandOption" />
                <Input inputRef={inputRef} value={machine} type="search" name="machineOption" id="machineOption" />
                <Input inputRef={inputRef} value={model} type="search" name="modelOption" id="modelOption" />
                <Input inputRef={inputRef} value={series} type="search" name="seriesOption" id="seriesOption" />
              </div>
              <div className="add-fleet-btn">
                <SanaButton
                  type="submit"
                  textKey={texts.addFleetItemBtn}
                  className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${btnStyles.btnAction} add-btn`}
                />
                <button className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} ${btnStyles.noIcon} ${btnStyles.btnAction}`} onClick={() => handleClearSelection()}>{texts.clearSelection}</button>
              </div>
            </div>
          </div>
        </form>
        </div>
      </Modal>
    </>
  );
};

export default memo(AddFleetItem);

function useAddFleetItemTexts() {
  const {
    texts: [
      addFleetItemBtn,
      addPopupHeader,
      addSuccessfulPopup,
      addFleetItem,
      brand,
      machine,
      series,
      model,
      quantity,
      selectBrand,
      selectMachine,
      selectSeries,
      selectModel,
      popupDescription,
      validation,
      clearSelection,
      UpdatedExistingItem,
    ],
    loaded: textsLoaded,
  } = useSimpleTexts([
    'ButtonText_AddFleetItem',
    'FleetItem_AddPopupHeader',
    'FleetItem_AddSuccessfully',
    'FleetItem_Add',
    'BrandId',
    'MachineId',
    'SeriesId',
    'ModelId',
    'FleetItem_Quantity',
    'Select_Brand',
    'Select_Machine',
    'Select_Series',
    'Select_Model',
    'Fleet_Popup_Description',
    'Fleet_Popup_Validation',
    'ButtonText_ClearSelection',
    'UpdatedExistingItem',
  ]);

  return {
    texts: {
      addFleetItemBtn,
      addPopupHeader,
      addSuccessfulPopup,
      addFleetItem,
      brand,
      machine,
      series,
      model,
      quantity,
      selectBrand,
      selectMachine,
      selectSeries,
      selectModel,
      popupDescription,
      validation,
      clearSelection,
      UpdatedExistingItem,
    },
    textsLoaded,
  };
}
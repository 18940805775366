// only with relative paths and named import here, the IntelliSense for component props in add-ons will work correctly
import { routesBuilder } from '../../webstore/routes';

export const homePage = () => routesBuilder.forHome();
export const loginPage = () => routesBuilder.forLogin();
export const myAccountPage = () => routesBuilder.forMyAccount();
export const newsPage = () => routesBuilder.forNewsOverview();
export const newsItemPage = id => routesBuilder.forNewsDetail(id);
export const basketPage = () => routesBuilder.forBasket();
export const checkoutPage = () => routesBuilder.forCheckout();
export const contactUsPage = () => routesBuilder.forContactUs();
export const productPage = id => routesBuilder.forProduct(id);
export const ordersPage = () => routesBuilder.forOrders();
export const existingaccountemail = () => routesBuilder.forExistingAccountEmail();//[107333][JMC] 3.9 Customer Registration � Avoid duplicate accounts OPTION 2
export const existingaccountphone = () => routesBuilder.forExistingAccountPhone();
export const accountVerification = () => routesBuilder.forAccountVerification();
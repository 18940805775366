//[107333][JMC] 3.9 Customer Registration � Avoid duplicate accounts OPTION 2
import styles from './AccountVerification.module.scss';
import { memo } from 'react';
import { Link, BackLink } from 'components/primitives/links';
import { useSanaTexts, RichText} from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Helmet } from 'react-helmet';
import { InfoAlert } from 'components/primitives/alerts';
import { Placeholder } from 'components/primitives/placeholders';
import { usePageTitle } from 'components/objects/hooks';
import { makeSimpleText, makeRichText } from 'utils/render';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useDispatch } from 'react-redux';

const accountVerification = () => {
  const {
    loaded,
    texts: [title, loginLink ,homeLinkText],
  } = useSanaTexts([
    'Registration_AccountVerification_Title',
    'GoTo_LoginLink',
    'GoTo_HomepageLink',
  ]);

  const pageTitle = usePageTitle(makeSimpleText(title));

  const dispatch = useDispatch();
  
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <h1>{title}</h1>
      <p className={styles.description}>
        <RichText
          textKey="Registration_AccountVerification_Description"          
        />
      </p>
      <ul className={styles.actionsList}>
        <li>
          <Link
            className={`${linkStyles.arrowed} ${styles.homeLink}`}
            to={routesBuilder.forLogin}            
          >
            {loginLink}
          </Link>
        </li>
        <li>          
          <Link
            className={`${linkStyles.arrowed} ${styles.homeLink}`}
            to={routesBuilder.forHome}
          >
            {homeLinkText}
          </Link>          
        </li>

      </ul>
    </>
  );
}


export default memo(accountVerification);
import styles from './Registration.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import headingStyles from 'components/primitives/headings/Heading.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import {
  SanaForm, EntityTemplateFields, FormGroup,
  NewPasswordFormGroup, CaptchaFormGroup, useCaptchaOnSubmit, extractEscapedTemplateFields,
} from 'components/objects/forms';
import { EmailAddressField, PasswordField } from 'components/objects/forms/fields';
import { SimpleText, RichText, useSimpleTexts } from 'components/sanaText';
import { connect, useSelector } from 'react-redux';
import { register } from 'behavior/user';
import { toasts } from 'behavior/toasts';
import { FormLockKeys } from 'behavior/pages';
import { formatText } from 'utils/formatting';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { useModal } from 'components/objects/modals';
import ExistingAccountMessage from './ExistingAccountMessage';
import ExistingAccountModal from './ExistingAccountModal';
import { useOnChange } from 'utils/hooks';

export const REGISTRATION_FORM_NAME = 'Registration';

const Form = ({ templateFields, registrationResult, register, navigateTo }) => {
  const [isSpinnerDisplayed, displaySpinner] = useState(false);

  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    displaySpinner(true);
    register(createRegistrationData(formData, templateFields));
  }, FormLockKeys.Registration, [templateFields]);

  const { name, email } = useSelector(state => state.user);
  const { opened, toggle } = useModal();
  

  useEffect(() => {

    if (registrationResult == null)
      return;
    //[107333][JMC] 3.9 Customer Registration � Avoid duplicate accounts OPTION 2
    if (registrationResult.isShowExistingAccountPhone) {
      navigateTo(routesBuilder.forExistingAccountPhone(registrationResult.registerPhone));
    }
    
    if (registrationResult.isShowExistingAccountEmail) {
      debugger
      navigateTo(routesBuilder.forExistingAccountEmail(registrationResult.registerEmail));      
    }

    if (registrationResult.isRegistered) {
      toasts.success(<RichText textKey="RegistrationForm_SuccessfullyRegistered" disableInsiteEditor />);      
      return;
    }

    if (registrationResult.failureText) {
      toasts.error(registrationResult.failureText);
    }

    displaySpinner(false);
  },[registrationResult]);

  useOnChange(() => {
    toggle();
  }, [registrationResult], false);

  const anyCustomerDataFields = !!(templateFields && templateFields.length);
  const headingClasses = `${headingStyles.heading} h3`;

  const [
    emailTitle,
    passwordTitle,
    minPasswordPlaceholderTemplate,
    confirmPasswordTitle,
    comparisonErrorMessage,
  ] = useSimpleTexts([
    'EmailAddress',
    'Password',
    'CreateAccount_MinPasswordLength',
    'ConfirmPassword',
    'CreateAccount_DuplicatePasswordError',
  ]).texts;

  const minPasswordLength = useSelector(({ settings: { profile } }) => profile && profile.minPasswordLength);
  const newPasswordPlaceholder = formatText(minPasswordPlaceholderTemplate, [minPasswordLength]);
  const showExistingModel = registrationResult != null && registrationResult.isShowExistingAccountPopup ? true : false;

  return (
    <>
      <SanaForm
        onSubmit={useHandlerLockerInPreview(onSubmit)}
        name={REGISTRATION_FORM_NAME}
        className={styles.form}
      >
        <Row>
          {anyCustomerDataFields && (
            <Col xs={12} md={6}>
              <fieldset className={`${formStyles.fieldset} ${styles.customerDataSection}`}>
                <legend className={headingClasses}>
                  <SimpleText textKey="CreateAccount_PersonalDataHeader" />
                </legend>
                <div className={styles.formContent}>
                  <EntityTemplateFields applyNamesEscaping fields={templateFields} />
                </div>
              </fieldset>
            </Col>
          )}
          <Col
            xs={12}
            md={anyCustomerDataFields ? 6 : 8}
            lg={anyCustomerDataFields ? null : 7}
          >
            <fieldset className={formStyles.fieldset}>
              <legend className={headingClasses}>
                <SimpleText textKey="CreateAccount_LoginDataHeader" />
              </legend>
              <div className={styles.loginSectionDescription}>
                <RichText textKey="CreateAccount_LoginDataDescriptionLabel" />
              </div>
              <div className={styles.formContent}>
                <FormGroup
                  fieldName="email"
                  fieldTitle={emailTitle}
                  fieldComponent={EmailAddressField}
                  maxLength={80}
                  required
                  validation={{
                    required: true,
                    uniqueEmail: true,
                  }}
                />
                <NewPasswordFormGroup
                  fieldName="password"
                  fieldTitle={passwordTitle}
                  placeholder={newPasswordPlaceholder}
                  userData={[name, email]}
                  userInputs={['Address.Name', 'email']}
                />
                <FormGroup
                  fieldName="confirmPassword"
                  fieldTitle={confirmPasswordTitle}
                  fieldComponent={PasswordField}
                  autoComplete="off"
                  maxLength={30}
                  required
                  validation={{
                    required: { allowWhitespace: true },
                    equalsTo: { to: 'password', message: comparisonErrorMessage },
                  }}
                />
                <CaptchaFormGroup ref={captchaRef} />
              </div>
            </fieldset>
            <div className={styles.formContent}>
              <SanaForm.SubmitRow textKey="Submit" btnClass={isSpinnerDisplayed ? btnStyles.loading : null} />
            </div>
          </Col>
        </Row>
      </SanaForm>
      {showExistingModel ?
        <ExistingAccountModal opened={opened} hide={toggle}>
          <ExistingAccountMessage />
        </ExistingAccountModal>
        : null}
    </>
  );
};

Form.propTypes = {
  templateFields: PropTypes.array,
  registrationResult: PropTypes.object,
  register: PropTypes.func.isRequired,
};

export default connect(undefined, { register, navigateTo })(Form);

function createRegistrationData(formData, templateFields) {
  const { email, password, captchaToken, confirmPassword, ...customerData } = formData;
  return { email, password, captchaToken, customerData: extractEscapedTemplateFields(customerData, templateFields) };
}
import styles from './FleetItems.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useRef, useState, useCallback, useEffect } from 'react';
import { Modal, useModal } from 'components/objects/modals';
import { useSimpleTexts } from 'components/sanaText';
import { filterFleetItems } from 'behavior/pages/fleetItems';
import { Select } from 'components/primitives/form';
import { useDispatch } from 'react-redux';
import { Input } from 'components/primitives/form';
import { DangerAlert } from 'components/primitives/alerts';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';

//TICKET 140184 [JMC] Content element - Search for fleet items
const FleetItemsBlock = ({ brandCategoryOption, machineCategoryOption, modelCategoryOption, seriesCategoryOption, url }) => {

  const { opened, show, hide } = useModal();
  const { texts } = useAddFleetItemTexts();
  const dispatch = useDispatch();
  const [isCleared, setCleared] = useState(false);
  const [showClearButton, setshowClearButton] = useState(false);

  const brandValue = brandCategoryOption && brandCategoryOption.length === 1 ? brandCategoryOption[0].id : '';
  const [brand, setBrand] = useState({ brandValue });
  useEffect(() => {
    setCleared(false);
    if (brandValue !== '') {
      setBrand(brandValue);
    }
  }, [brandValue && brandValue !== '']);

  const machinValue = machineCategoryOption && machineCategoryOption.length === 1 ? machineCategoryOption[0].id : '';
  const [machine, setMachine] = useState({ machinValue });
  useEffect(() => {
    setCleared(false);
    if (machinValue !== '') {
      setMachine(machinValue);
    }
  }, [machinValue && machinValue !== '']);

  const seriesValue = seriesCategoryOption && seriesCategoryOption.length === 1 ? seriesCategoryOption[0].id : '';
  const [series, setSeries] = useState({ seriesValue });
  useEffect(() => {
    setCleared(false);
    if (seriesValue !== '') {
      setSeries(seriesValue);
    }
  }, [seriesValue && seriesValue !== '']);

  const [model, setModel] = useState('');
  const modelValue = modelCategoryOption && modelCategoryOption.length === 1 ? modelCategoryOption[0].id : '';

  useEffect(() => {
    setCleared(false);
    if (modelValue && modelValue !== '') {
      setModel(modelValue);
    }
  }, [modelValue && modelValue !== '']);

  const [showValidation, setShowValidation] = useState(false);
  const inputRef = useRef(null);

  function handleSubmit(e) {
    e.preventDefault();

    if (isCleared)
      return;

    const form = document.forms['AddFleetItemForm'];
    const model = form && form.elements['modelOption'].value;


    if (!model) {
      setShowValidation(true);
      return;
    }
    else {
      setShowValidation(false);
    }
  }

  function handleClearSelection() {

    setCleared(true);
    setBrand('');
    setMachine('');
    setModel('');
    setSeries('');
    dispatch(filterFleetItems('', '', '', ''));
    setShowValidation(false);
    return;
  }

  const BrandChange = useCallback(value => {
    setBrand(value);
    const form = document.forms['AddFleetItemForm'];
    const machine = form && form.elements['machineOption'].value;
    const model = form && form.elements['modelOption'].value;
    const series = form && form.elements['seriesOption'].value;
    dispatch(filterFleetItems(value, machine, series, model));
    return;
  }, []);

  const MachineChange = useCallback(value => {
    setMachine(value);
    const form = document.forms['AddFleetItemForm'];
    const brand = form && form.elements['brandOption'].value;
    const model = form && form.elements['modelOption'].value;
    const series = form && form.elements['seriesOption'].value;
    dispatch(filterFleetItems(brand, value, series, model));
    return;
  }, []);

  const SeriesChange = useCallback(value => {
    setSeries(value);
    const form = document.forms['AddFleetItemForm'];
    const machine = form && form.elements['machineOption'].value;
    const brand = form && form.elements['brandOption'].value;
    const model = form && form.elements['modelOption'].value;
    dispatch(filterFleetItems(brand, machine, value, model));
    return;
  }, []);

  const ModelChange = useCallback(value => {
    setModel(value);
    const form = document.forms['AddFleetItemForm'];
    const brand = form && form.elements['brandOption'].value;
    const machine = form && form.elements['machineOption'].value;
    const series = form && form.elements['seriesOption'].value;
    dispatch(filterFleetItems(brand, machine, series, value));
    setshowClearButton(!showClearButton);
    return;
  }, [model]);

  const selectableFieldOptions = brandCategoryOption && brandCategoryOption.length > 0? [{ name: texts.selectBrand, value: null }, ...brandCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [{ name: texts.selectBrand, value: null }];
  const selectableFieldOptions1 = machineCategoryOption && machineCategoryOption.length > 0 ? [{ name: texts.selectMachine, value: null }, ...machineCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [{ name: texts.selectMachine, value: null }];
  const selectableFieldOptions2 = seriesCategoryOption && seriesCategoryOption.length > 0 ? [{ name: texts.selectSeries, value: null }, ...seriesCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [{ name: texts.selectSeries, value: null }];
  const selectableFieldOptions3 = modelCategoryOption && modelCategoryOption.length > 0 ? [{ name: texts.selectModel, value: null }, ...modelCategoryOption.map(c => ({ name: c.name, value: c.id }))]
    : [{ name: texts.selectModel, value: null }];

  const handleClick = () => {
    dispatch(filterFleetItems('', '', '', ''));
    show();
    handleClearSelection();
    setCleared(false);
  };
  
  return (
    <>
      <div className="Grid_row SanaForm_form-row">
        <div className="Grid_col-12">
          <button className={`${btnStyles.btn} ${btnStyles.btnBig} ${btnStyles.noIcon} ${btnStyles.btnAction} ${styles.fleetBtn}`}  onClick={handleClick}>{texts.searchFleetItemBtn}</button>
        </div>
      </div>

      <Modal opened={opened} hide={hide} className={styles.modelWrapper}>
      <div className=" Grid_col-6 center-align add-fleet ">
          <h2 className={styles.h2Block}>{texts.addPopupHeader}</h2>
        <div>
            <h4 className={styles.h4Block}>{texts.popupDescription}</h4>
        </div>
          <div>
            <h5 className={styles.h4Block}>{texts.dropDownInfo}</h5>
          </div>
          <form
            name="AddFleetItemForm"
            onSubmit={handleSubmit}
          >
          <div>
            <DangerAlert style={{ display: showValidation ? 'block' : 'none' }}>{texts.validation}</DangerAlert>
          </div>
          <div className="Grid_row">
            <div className="add-fleet-main-wrapper">
              <div className="add-fleet-wrapper">
                  <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">

                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="machineId">
                      {texts.brand}

                    </label>
                  </div>
                    <div className={`${styles.fullWidth} SanaForm_field `}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions}
                      onChange={BrandChange}
                      value={brand}
                    />
                  </div>

                </div>
                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="machineId">

                      {texts.machine}
                    </label>
                  </div>
                  <div className={styles.fullWidth}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions1}
                      onChange={MachineChange}
                      value={machine}
                    />
                  </div>
                </div>
              </div>

              <div className="add-fleet-wrapper">

                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="machineId">{texts.series}</label>
                  </div>
                  <div className={styles.fullWidth}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions2}
                      onChange={SeriesChange}
                      value={series}
                    />
                  </div>

                </div>

                <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row SanaForm_control form-row-control">
                  <div className="SanaForm_label form-row-label">
                    <label className="SanaForm_label form-row-label" htmlFor="seriesId">{texts.model}</label>
                  </div>
                  <div className={styles.fullWidth}>
                    <Select
                      id="agreement_selector_dropdown"
                      className={styles.fullWidth}
                      optionClassName="test"
                      items={selectableFieldOptions3}
                      onChange={ModelChange}
                      value={model}
                    />
                  </div>

                </div>
              </div>
            </div>

            <div className="Grid_col-12 Grid_align-self-auto SanaForm_form-row">
              <div style={{ display: 'none' }}>
                <Input inputRef={inputRef} value={brand} type="search" name="brandOption" id="brandOption" />
                <Input inputRef={inputRef} value={machine} type="search" name="machineOption" id="machineOption" />
                <Input inputRef={inputRef} value={model} type="search" name="modelOption" id="modelOption" />
                <Input inputRef={inputRef} value={series} type="search" name="seriesOption" id="seriesOption" />
              </div>
                <div className="add-fleet-btn">
                  <Link
                    className={`${btnStyles.btn} ${btnStyles.btnExtraSmall}  ${btnStyles.btnAction} Link_arrowed`}
                    url={url}
                    style={{ display: showClearButton ? 'block' : 'none' }}
                  >
                    <SimpleText textKey="FleetItem_Search" />
                  </Link>
                <button className={`${btnStyles.btn} ${btnStyles.btnExtraSmall}  ${btnStyles.btnAction}`} onClick={() => handleClearSelection()}>{texts.clearSelection}</button>
              </div>
            </div>
          </div>
        </form>
        </div>
      </Modal>
    </>
  );
};

export default connect(({
  fleetItems: { brandCategoryOption, machineCategoryOption, modelCategoryOption, seriesCategoryOption, url },
  localization,
}) => ({
  brandCategoryOption,
  machineCategoryOption,
  modelCategoryOption,
  seriesCategoryOption,
  url,
}))(FleetItemsBlock);

function useAddFleetItemTexts() {
  const {
    texts: [
      searchFleetItemBtn,
      addPopupHeader,
      addSuccessfulPopup,
      searchFleetItem,
      brand,
      machine,
      series,
      model,
      quantity,
      selectBrand,
      selectMachine,
      selectSeries,
      selectModel,
      popupDescription,
      validation,
      clearSelection,
      UpdatedExistingItem,
      dropDownInfo,
    ],
    loaded: textsLoaded,
  } = useSimpleTexts([
    'ButtonText_SearchFleetItem',
    'FleetItem_SearchPopupHeader',
    'FleetItem_AddSuccessfully',
    'FleetItem_Search',
    'BrandId',
    'MachineId',
    'SeriesId',
    'ModelId',
    'FleetItem_Quantity',
    'Select_Brand',
    'Select_Machine',
    'Select_Series',
    'Select_Model',
    'Fleet_Popup_Search_Description',
    'Fleet_Popup_Validation',
    'ButtonText_ClearSelection',
    'UpdatedExistingItem',
    'DropDownInfo',
  ]);

  return {
    texts: {
      searchFleetItemBtn,
      addPopupHeader,
      addSuccessfulPopup,
      searchFleetItem,
      brand,
      machine,
      series,
      model,
      quantity,
      selectBrand,
      selectMachine,
      selectSeries,
      selectModel,
      popupDescription,
      validation,
      clearSelection,
      UpdatedExistingItem,
      dropDownInfo,
    },
    textsLoaded,
  };
}
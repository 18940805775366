import styles from './HeaderContent.module.scss';
import { Container } from 'components/primitives/grid';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { PageContent } from 'components/objects/pages';
import { useSelectedFacetsTitle } from '../hooks';
import { makeRichText } from 'utils/render';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';

const HeaderContent = ({ title, hideTitle, description, backgroundColor, backgroundImage, headerContent }) => {
  const facetsTitle = useSelectedFacetsTitle();
  const style = {};
  if (backgroundColor)
    style.backgroundColor = backgroundColor;

  if (backgroundImage)
    style.backgroundImage = `url("${backgroundImage}")`;

  return (
    <>
      <div
        className={`${styles.coloredHeaderDesktop} ${styles.header} ${backgroundImage ? styles.customBg : ''}`}
        style={style}
      >
        <Container className={styles.center}>
          <PageBreadcrumb />
          <div className={hideTitle ? styles.combinedHideTitle : styles.combined}>
            <div className={styles.h1Holder}>
              <h1>
                {!hideTitle && title}
                {facetsTitle &&
                  <span className={styles.facetsTitle}>
                    {facetsTitle}
                  </span>
                }
              </h1>
            </div>
            {description &&
              <div className={`fr-view ${styles.combinedDescription}`}>
                {makeRichText(description)}
              </div>
            }
          </div>
        </Container>
      </div>
      <PageContent content={headerContent} position="header" />
    </>
  );
};

HeaderContent.propTypes = {
  title: PropTypes.string.isRequired,
  hideTitle: PropTypes.string.isRequired, //[125894] [JMC] Breadcrumb trail - Hide title
  description: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  headerContent: PropTypes.any,
};

export default connect(({ page }) => ({
  title: page.pageTitle,
  hideTitle: page.hideTitle, //[125894] [JMC] Breadcrumb trail - Hide title
  description: page.description,
  backgroundColor: page.backgroundColor,
  backgroundImage: page.backgroundImage,
}))(
  HeaderContent,
);
//[107333][JMC] 3.9 Customer Registration � Avoid duplicate accounts OPTION 2
import styles from './ExistingAccountPhone.module.scss';
import { memo } from 'react';
import { Link, BackLink } from 'components/primitives/links';
import { useSanaTexts, RichText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Helmet } from 'react-helmet';
import { InfoAlert } from 'components/primitives/alerts';
import { Placeholder } from 'components/primitives/placeholders';
import { usePageTitle } from 'components/objects/hooks';
import { makeSimpleText, makeRichText } from 'utils/render';
import linkStyles from 'components/primitives/links/Link.module.scss';

const existingAccountPhone = () => {
const {
    loaded,
  texts: [title, homeLinkText],
  } = useSanaTexts([
    'Registration_ExistingAccountPhone_Title',
    'GoTo_HomepageLink',
  ]);

  const pageTitle = usePageTitle(makeSimpleText(title));
  const phone = new URL(window.location.href).searchParams.get("phone");
  
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <h1>{title}</h1>
      <p className={styles.description}>
        <RichText
          textKey="Registration_ExistingAccountPhone_Description"
          formatWith={[phone]}
        />
      </p>
      <ul className={styles.actionsList}>
        <li>
          <Link
            className={`${linkStyles.arrowed} ${styles.homeLink}`}
            to={routesBuilder.forHome}
          >
            {homeLinkText}
          </Link>
        </li>

      </ul>
    </>
  );
}

export default memo(existingAccountPhone);
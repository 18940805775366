import { fleetItemsQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { fleetItemsReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';
import DateOnly from 'date-only';
import { useIsOffline } from 'utils/hooks';
export const size = 5;

export default ({ params }, _state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi(loadSystemPageQuery('fleetItems')).pipe(
      map(({ pages: { fleetItems: page } }) => {
        if (!page)
          return null;

        page.component = PageComponentNames.FleetItems;
        const fleetItems = Array.from(Array(5)).map(() => ({ id: generateKey(), lines: [] }));
        
        return { page, action$: of(fleetItemsReceived({ fleetItems })) };
      }),
      initSystemPageContent(),
    );
  }

  const filter = normalizeFilter(params && params.filter);
  const input = createOptions(params,filter);

  return api.graphApi(fleetItemsQuery, { input }).pipe(

    map(({ pages: { fleetItems: page }, fleetItems, addFleetItems   }) => {

      if (!page)
        return null;

      page.component = PageComponentNames.FleetItems;
      return { page, action$: of(fleetItemsReceived({ fleetItems }, { addFleetItems } , false )) };
    }),
    initSystemPageContent(),
  );
};

export function createOptions(params,filter) {
  const index =  0;
  const page = { index, size };

  return { page };
}

export function normalizeFilter(filter) {
  if (filter && filter.orderDate)
    return filter;

  const from = new Date();
  let fromDate = from.getDate();
  const fromMonth = from.getMonth() - 3;
  const fromMonthLastDay = getNumberOfDaysInMonth(fromMonth + 1, from.getFullYear());
  if (fromMonthLastDay < fromDate)
    fromDate = fromMonthLastDay;

  from.setMonth(fromMonth, fromDate);

  return { ...filter, orderDate: { from: DateOnly.toISOString(from) } };
}

function getNumberOfDaysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".HeaderContent_colored-header-desktop{position:relative}.HeaderContent_colored-header-desktop:before{content:\"\";box-shadow:inset 0 6px 3px -3px rgba(0,0,0,.1);position:absolute;top:0;left:0;right:0;height:6px}.HeaderContent_header .HeaderContent_center{padding-top:1.6em}.HeaderContent_font-title{color:var(--regularTitle_FontColor,#333)}.HeaderContent_h1-holder{padding-top:.5em;margin-bottom:.5em}.HeaderContent_h1-holder h1{margin:0;font-size:calc(var(--productList_Title_FontSize, 4.6em)/2)}.HeaderContent_facets-title{font-size:var(--productList_FacetsTitle_FontSize,22px);display:block;text-transform:none;color:var(--normalText_FontColor,grey)}.HeaderContent_combined,.HeaderContent_combined-hide-title{padding-bottom:.5em}.HeaderContent_combined-description{word-break:break-word}@supports (-ms-ime-align:auto){.HeaderContent_combined-description{overflow-wrap:break-word}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.HeaderContent_combined-description{word-wrap:break-word}}.HeaderContent_custom-bg{background-origin:padding-box;background-position:0 0;background-repeat:no-repeat;background-size:cover}.HeaderContent_custom-bg .HeaderContent_center{position:relative;z-index:1}.HeaderContent_custom-bg:before{background-color:hsla(0,0%,78.4%,.5);bottom:0;height:auto!important;z-index:0}@media (min-width:var(--breakpoints_Large,1280)px),print{.HeaderContent_custom-bg{background-size:auto}}@media (min-width:var(--breakpoints_Medium,960)px),print{.HeaderContent_combined{margin-top:2em;padding-bottom:.7em}.HeaderContent_combined-hide-title{margin-top:0}.HeaderContent_custom-bg{background-origin:content-box;background-position:top}.HeaderContent_colored-header-desktop{background-color:var(--header_Alt_BackgroundColor,#f2f2f2)}.HeaderContent_center{padding-top:1.7em}.HeaderContent_h1-holder h1{font-size:var(--productList_Title_FontSize,4.6em)}}", ""]);

// exports
exports.locals = {
	"colored-header-desktop": "HeaderContent_colored-header-desktop",
	"coloredHeaderDesktop": "HeaderContent_colored-header-desktop",
	"header": "HeaderContent_header",
	"center": "HeaderContent_center",
	"font-title": "HeaderContent_font-title",
	"fontTitle": "HeaderContent_font-title",
	"h1-holder": "HeaderContent_h1-holder",
	"h1Holder": "HeaderContent_h1-holder",
	"facets-title": "HeaderContent_facets-title",
	"facetsTitle": "HeaderContent_facets-title",
	"combined": "HeaderContent_combined",
	"combined-hide-title": "HeaderContent_combined-hide-title",
	"combinedHideTitle": "HeaderContent_combined-hide-title",
	"combined-description": "HeaderContent_combined-description",
	"combinedDescription": "HeaderContent_combined-description",
	"custom-bg": "HeaderContent_custom-bg",
	"customBg": "HeaderContent_custom-bg"
};